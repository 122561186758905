/* eslint-disable react-hooks/exhaustive-deps */
/** @jsxImportSource @emotion/react */

import { DriveEta } from "@material-ui/icons";
import React from "react";
import { useHistory, useRouteMatch } from "react-router";
import { backendMediaUrlParser } from "../../utils";
import ThreeViewer from "./ThreeViewer";

const Viewer3DPage = (props: {}) => {
  //24: file-asset-model
  const { path } = useRouteMatch();
  const history = useHistory();

  const pathTokens = (history?.location?.pathname || "").split("/") || [""];
  const opaque = pathTokens[pathTokens.length - 1];
  if (opaque.length != 24 * 3) return <div children="Invalid embed url" />;

  const projectId = opaque.substring(24 * 2);
  const assetId = opaque.substring(24, 24 * 2);
  const fileId = opaque.substring(0, 24);

  // const url = projectId + "/" + assetId + "/" + fileId + ".glb";
  const url = projectId + "/" + assetId + "/model.glb";
  const model = backendMediaUrlParser(url);
  // console.log("🟨 to load model", projectId, assetId, fileId, model);

  return <ThreeViewer model={model} />;
};

export default Viewer3DPage;
