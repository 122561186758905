import { createMuiTheme } from "@material-ui/core/styles";
import { red } from "@material-ui/core/colors";
import R, { hexWithOpacity } from "./R";

// Create a theme instance.
const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#1A59E7",
      // light: "#0C3A76",
    },
    secondary: {
      main: "#00DA82",
      // light: "#60E2AA",
    },
    text: {
      primary: R.colors.deepBlue,
      secondary: "#646874",
    },
    error: {
      main: red.A400,
    },
    success: {
      main: "#07B338",
    },
    warning: {
      main: "#FF9400",
    },
    background: {
      default: "#fff",
    },
  },
  overrides: {
    MuiSnackbar: {
      root: {
        borderRadius: 0,
        color: "green",
      },
    },
    MuiAccordion: {
      root: {
        boxShadow: "none",
        borderRadius: "5px !important",
        border: "1px solid #e7e7e7",
      },
    },
    MuiTab: {
      root: {
        padding: 0,
      },
    },
    MuiTooltip: {
      tooltip: {
        pointerEvents: "all",
        backgroundColor: R.colors.deepBlue,
        padding: 18,
        borderRadius: 20,
      },
      arrow: {
        color: R.colors.deepBlue,
      },
    },
    MuiLinearProgress: {
      determinate: {
        backgroundColor: "#e7e7e7",
      },
    },
    MuiButton: {
      root: {
        height: 40,
        borderRadius: 30,
        padding: "10px 20px",
        fontWeight: 400,
        textTransform: "uppercase",
        "&$disabled": {
          background: "#D6D6D6 !important",
          color: "white !important",
        },
      },
      contained: {
        padding: "10px 20px",
        boxShadow: "none",
        background: "rgba(131, 165, 239, 0.15)",
        backgroundColor: "rgba(131, 165, 239, 0.15)",
        color: "#1A59E7",
        "&:hover": {
          boxShadow: "none",
          backgroundColor: "rgba(131, 165, 239, 0.30)",
        },
      },
      outlined: {
        padding: "10px 20px",
        minWidth: 90,
        boxShadow: "0px 14px 23px rgba(28, 37, 44, 0.04)",
      },
      text: {
        fontSize: 14,
        lineHeight: "17px",
        fontWeight: 400,
        // textTransform: "initial",
        "&$disabled": {
          background: "transparent !important",
          color: "#D6D6D6 !important",
        },
        padding: "13px 30px",
        // boxShadow: "0px 14px 23px 0px rgba(28, 37, 44, 0.04)",
        boxShadow: "none",
      },
      containedPrimary: {
        background: "linear-gradient(90deg, #1A59E7 0%, #0066FF 100%)",
        "&:hover": {
          boxShadow: "5px 15px 30px rgba(9, 64, 116, 0.3)",
        },
      },
      containedSecondary: {
        color: "white",
        "&:hover": {
          boxShadow: "5px 15px 30px rgba(0, 218, 130, 0.3)",
        },
      },
    },
    MuiPaper: {
      root: {
        color: "inherit",
      },
      outlined: {
        backgroundColor: hexWithOpacity("#FFFFFF", 0.5),
        border: `2px solid ${hexWithOpacity("#FFFFFF", 0.5)}`,
      },
    },
    MuiCard: {
      root: {
        borderRadius: 6,
        boxShadow: "0px 4px 24px rgba(0, 0, 0, 0.06)",
      },
    },
    MuiCardActions: {
      root: {
        justifyContent: "flex-end",
      },
    },
    MuiChip: {
      root: {
        marginRight: 8,
        marginTop: 4,
        marginBottom: 4,
        backgroundColor: hexWithOpacity("#FE3D3D", 0.04),
        color: "#FE3D3D",
        fontSize: 12,
        fontWeight: 600,
        textTransform: "uppercase",
      },
    },
    MuiDrawer: {
      paper: {
        // backgroundColor: 'transparent',
        backgroundColor: R.colors.deepBlue,
        color: "white",
        maxWidth: "80vw",
        width: R.dimens.drawerWidth,
      },
      paperAnchorDockedLeft: {
        borderRight: "0px",
      },
    },
    MuiFilledInput: {
      root: {
        borderRadius: 12,
        borderTopLeftRadius: 12,
        borderTopRightRadius: 12,
        color: "red",
        backgroundColor: hexWithOpacity("#E7ECF1", 0.3),
        "&:hover": {
          backgroundColor: hexWithOpacity("#E7ECF1", 0.4),
        },
        "&.Mui-focused": {
          backgroundColor: hexWithOpacity("#E7ECF1", 0.5),
        },
      },
      input: {
        padding: "16px 12px 15px",
        fontSize: 14,
        lineHeight: 1.6,
        color: R.colors.primary,
      },
      multiline: {
        padding: "16px 12px 15px",
      },
      underline: {
        "&::before, &::after": {
          border: 0,
        },
        "&:hover": {
          border: 0,
          "&::before": {
            border: 0,
          },
        },
      },
    },
    MuiIconButton: {
      root: {
        color: R.colors.deepBlue,
      },

      // root: {
      //   backgroundColor: hexWithOpacity(R.colors.primary, 0.06),
      //   color: "rgba(9, 64, 116, .6)",
      //   borderRadius: 12,
      //   "& .MuiIconButton-label": {
      //     width: 28,
      //     height: 28,
      //   },
      //   "@media screen and (max-width: 959px)": {
      //     padding: 12,
      //     "& .MuiIconButton-label": {
      //       width: 16,
      //       height: 16,
      //     },
      //   },
      // },
    },
    MuiListItem: {
      root: {
        marginBottom: 10,
        paddingTop: 0,
        paddingBottom: 0,
        borderRadius: 5,
        "&:hover": {
          cursor: "pointer",
        },
      },
    },
    MuiListItemText: {
      primary: {
        fontFamily: "Nunito sans",
        fontSize: 14,
        fontWeight: 600,
        letterSpacing: 0.1,
      },
    },
    MuiListItemIcon: {
      root: {
        minWidth: 36,
        color: "rgba(0, 0, 0, .6)",
      },
    },
    MuiMenu: {
      paper: {
        borderRadius: 12,
      },
      list: {
        paddingTop: 0,
        paddingBottom: 0,
        borderRadius: 12,
      },
    },
    MuiSelect: {
      select: {
        "&:focus": {
          backgroundColor: "rgba(0, 0, 0, 0)",
        },
      },
    },
    MuiDialog: {
      paper: {
        borderRadius: 20,
        padding: 30,
      },
    },

    MuiCheckbox: {
      root: {
        height: 18.5,
        width: 18.5,
        border: "1.5px solid orange",
        borderRadius: 4,
        "& svg": {
          "& path": {
            opacity: 0,
            boxShadow: "0px 4px 8px rgba(28, 37, 44, 0.08)",
            color: "#E7E7E7",
            d: 'path("")',
            // d: 'path("M13 5.67688H23V2.67688H13V5.67688ZM26.5 9.17688V19.1769H29.5V9.17688H26.5ZM23 22.6769H13V25.6769H23V22.6769ZM9.5 19.1769V9.17688H6.5V19.1769H9.5ZM13 22.6769C11.067 22.6769 9.5 21.1099 9.5 19.1769H6.5C6.5 22.7667 9.41015 25.6769 13 25.6769V22.6769ZM26.5 19.1769C26.5 21.1099 24.933 22.6769 23 22.6769V25.6769C26.5899 25.6769 29.5 22.7667 29.5 19.1769H26.5ZM23 5.67688C24.933 5.67688 26.5 7.24388 26.5 9.17688H29.5C29.5 5.58703 26.5899 2.67688 23 2.67688V5.67688ZM13 2.67688C9.41015 2.67688 6.5 5.58703 6.5 9.17688H9.5C9.5 7.24388 11.067 5.67688 13 5.67688V2.67688Z")',
          },
        },
        "&$checked": {
          backgroundColor: "green",
          "& svg": {
            width: 4,
            height: 8,
            border: "1.5px solid white",
            borderTop: 0,
            borderLeft: 0,
            transform: "rotate(45deg)",
            // "& path": {
            //   transform: "translate(7px, 7px)",
            //   fill: "none",
            //   stroke: "red",
            //   strokeWidth: 2,
            //   strokeLinecap: "round",
            //   d: 'path("M8.75 1.67688L3.59375 7.30188L1.25 4.74506")',
            // },
          },
        },
      },
      checked: {},
    },
  },
  props: {
    MuiCard: {
      elevation: 0,
    },
  },
  typography: {
    fontFamily: [
      "Nunito sans",
      "Open Sans",
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    allVariants: {
      // fontFamily: "Nunito sans", //? nunito not working atm
      fontWeight: 500,
      // color: R.colors.primary,
    },
    h1: {
      fontSize: 34,
      lineHeight: "120%",
    },
    h2: {
      fontSize: 24,
      lineHeight: "140%",
    },
    h3: {
      fontSize: 20,
      fontWeight: 600,
      lineHeight: "120%",
    },
    h4: {
      fontSize: 18,
      lineHeight: "170%",
      // "@media screen and (max-width: 959px)": {
      //   fontSize: 20,
      //   letterSpacing: 0.5,
      // },
    },
    subtitle1: {
      fontSize: 12,
      lineHeight: "140%",
      // "@media screen and (max-width: 959px)": {
      //   fontFamily: "Open Sans",
      //   fontSize: 14,
      //   fontWeight: 400,
      //   lineHeight: 1.6,
      //   letterSpacing: 0.25,
      //   color: hexWithOpacity(R.colors.primary, 0.74),
      // },
    },
    subtitle2: {
      fontSize: 14,
      fontWeight: 600,
      letterSpacing: 0.1,
    },
    body1: {
      fontFamily: "Karla",
      fontSize: 16,
      lineHeight: "150%",
    },
    body2: {
      fontFamily: "Nunito sans",
      fontSize: 12,
      fontWeight: 400,
      lineHeight: "150%",
    },
    button: {
      fontSize: 14,
      lineHeight: "120%",
    },
    caption: {
      fontSize: 12,
      lineHeight: "150%",
    },
    overline: {
      fontFamily: "Nunito sans",
      fontSize: 12,
      fontWeight: 600,
      lineHeight: 1.4,
      letterSpacing: 1.5,
    },
  },
});

export default theme;
