/* eslint-disable react-hooks/exhaustive-deps */
/** @jsxImportSource @emotion/react */

import { Material } from "three";
import * as THREE from "three";
import { Checkbox, IconButton } from "@material-ui/core";
import { constant, update } from "lodash";
import DropFiles from "./DropFiles";
import ColorPicker from "./ColorPicker";
import React, { ChangeEvent, useCallback, useRef, useState } from "react";
import { useDropzone } from "react-dropzone";
import MLDialog from "../../components/poppers";
import NumberedSlider from "./NumberedSlider";
import SmartEditor from "./SmartEditor";
import { MenuItem } from "rich-markdown-editor/dist/types";
import { FileCopyOutlined } from "@material-ui/icons";
import { useEffect } from "react";

const textureLoader = new THREE.TextureLoader();
const cubeTextureLoader = new THREE.CubeTextureLoader();

const gradientMaps = (function () {
  const threeTone = textureLoader.load("/images/textures/gradientMaps/threeTone.jpg");
  threeTone.minFilter = THREE.NearestFilter;
  threeTone.magFilter = THREE.NearestFilter;

  const fiveTone = textureLoader.load("/images/textures/gradientMaps/fiveTone.jpg");
  fiveTone.minFilter = THREE.NearestFilter;
  fiveTone.magFilter = THREE.NearestFilter;

  return {
    none: null,
    threeTone: threeTone,
    fiveTone: fiveTone,
  };
})();

//! ---

const largeFieldWidth = 100;

const MaterialEditor = (props: { material: Material; needsUpdate: (material: Material) => void }) => {
  const { material, needsUpdate } = props;
  const _material = material as any;
  const [updateFrame, setUpdateFrame] = useState(0);
  const repaint = () => setUpdateFrame((f) => f + 1);

  const materialInfo = (SupportedMaterials as any)[material?.type];

  if (!materialInfo) return <p children="material not supported" />;

  return (
    <div css={{ color: "white" }}>
      {/* <div css={{ display: "flex", flexDirection: "row" }}>
        <h3
          css={{ flex: 1, display: "flex", flexDirection: "row", alignItems: "center", textAlign: "left" }}
          children={material.type}
        />
        <IconButton
          css={{ ":hover": { backgroundColor: "rgba(255,255,255,0.2)" } }}
          children={<i className="material-icons" children="edit" css={{ color: "white" }} />}
        />
      </div> */}

      {GenericMaterialProps.map((p: string) => (
        <span key={p} children={renderProperty(p)} />
      ))}

      {/* <p children="---" /> */}

      {materialInfo.props.map((p: string) => (
        <span key={p} children={renderProperty(p)} />
      ))}

      <SmartEditor
        object={material}
        onUpdate={(m) => {
          needsUpdate(m);
          repaint();
        }}
      />
    </div>
  );

  function onPropertyChange(property: string, value: any, forceUpdate?: boolean) {
    const whoNeedsUpdate = ["alphaTest", "side", "vertexColors", "flatShading"];

    // console.log("🟨 property", property, value);
    _material[property] = value;

    if (forceUpdate || whoNeedsUpdate.includes(property)) {
      needsUpdate(_material);
      // console.log("needsUpdate");
      repaint();
    }

    // repaint();
  }

  function renderProperty(property: string) {
    switch (property) {
      //? maps (textures)
      case "envMaps":
        return propCubeTexture(property);
      case "map":
      case "roughnessMap":
      case "alphaMap":
        return propTexture(property);
      //? selects
      case "side":
        return propSelect(property, constants.side);
      case "combine":
        // todo combine (MeshLambertMaterial + MeshPhongMaterial)
        return null;
      case "gradientMap":
        // todo retrieve initialValue (with MeshToonMaterial)
        return propSelect(property, gradientMaps);
      case "linecap":
        return propSelect(property, ["butt", "round", "square"]);
      case "linejoin":
        return propSelect(property, ["round", "bevel", "miter"]);
      //? toggles
      case "transparent":
      case "visible":
      // case "wireframe": // todo (se esporto con wireframe converte il modello in un "LineSegment" non reversibile)
      case "vertexColors":
      case "fog":
      case "flatShading":
        return propToggle(property);
      case "color":
      case "emissive":
      case "specular":
        return propColor(property);
      //? sliders
      case "opacity":
      case "alphaTest":
      case "roughness":
      case "metalness":
      case "clearcoat":
      case "reflectivity":
      case "refractionRatio":
        return propSlider(property, 0, 1);
      case "shininess":
        return propSlider(property, 0, 100, 1);
      case "linewidth":
        return propSlider(property, 0, 10, 0.1);
    }
  }

  function propCubeTexture(property: string) {
    // todo - wip cube maps loader
    // const path = "/images/cube/";
    // const format = ".jpg";
    // const urls = [
    //   path + "px" + format,
    //   path + "nx" + format,
    //   path + "py" + format,
    //   path + "ny" + format,
    //   path + "pz" + format,
    //   path + "nz" + format,
    // ];

    // const reflectionCube = cubeTextureLoader.load(urls);
    // reflectionCube.format = THREE.RGBFormat;

    // const refractionCube = cubeTextureLoader.load(urls);
    // refractionCube.mapping = THREE.CubeRefractionMapping;
    // refractionCube.format = THREE.RGBFormat;

    // return {
    //   none: null,
    //   reflection: reflectionCube,
    //   refraction: refractionCube,
    // };
    return (
      <PropertyLayout
        title={property}
        control={<div css={{ textAlign: "right", padding: 8, width: largeFieldWidth }} children="WIP ⚒" />}
      />
    );
  }

  function propTexture(property: string) {
    return (
      <TexturePropertyEditor
        key={property}
        property={property}
        texture={_material[property]}
        onChange={(value) => onPropertyChange(property, value, true)}
      />
    );
  }

  function propColor(property: string) {
    return (
      <PropertyLayout
        title={property}
        control={
          <div css={{ padding: 8, width: largeFieldWidth }}>
            <ColorPicker defaultValue={_material[property]} onChange={(color) => onPropertyChange(property, color)} />
          </div>
        }
      />
    );
  }

  function propSelect(property: string, variants: any) {
    const isArray = Array.isArray(variants);
    return (
      <PropertyLayout
        title={property}
        control={
          <select
            css={{ width: largeFieldWidth, margin: "4px 8px" }}
            onChange={(e) => {
              const value = e.target.value;
              onPropertyChange(property, isArray ? value : variants[value]);
            }}
          >
            {(isArray ? variants : Object.keys(variants)).map((k: any) => (
              <option key={k} value={k} children={k} />
            ))}
          </select>
        }
      />
    );
  }

  function propSlider(property: string, min: number, max: number, step?: number) {
    return (
      <PropertyLayout
        title={property}
        control={
          <div css={{ padding: "2px 8px", width: largeFieldWidth }}>
            <NumberedSlider
              // value={_material[property]}
              defaultValue={_material[property]}
              onChange={(value) => onPropertyChange(property, value)}
              min={min}
              max={max}
              step={step || 0.01}
            />
          </div>
        }
      />
    );
  }

  function propToggle(property: string) {
    return (
      <PropertyLayout
        title={property}
        control={
          <Checkbox
            css={{ margin: 8 }}
            // checked={_material[property]}
            defaultChecked={_material[property]}
            onChange={(e) => onPropertyChange(property, e.target.checked)}
          />
        }
      />
    );
  }
};

const PropertyLayout = (props: { title: string; description?: string; control: any; onTitleClick?: () => void }) => {
  const { title, description, control, onTitleClick } = props;
  return (
    <div css={{ display: "flex", flexDirection: "row" }}>
      <div
        css={{
          padding: "2px 8px",
          textTransform: "capitalize",
          flex: 1,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          textAlign: "left",
        }}
      >
        <span
          onClick={onTitleClick}
          children={(onTitleClick ? "▿ " : "") + title}
          css={{ cursor: onTitleClick ? "pointer" : "" }}
        />
        {description && <div css={{ fontSize: "0.8em" }} children={description} />}
      </div>
      {/* <IconButton
        css={{ ":hover": { backgroundColor: "rgba(255,255,255,0.2)" } }}
        children={<i className="material-icons" children="edit" css={{ color: "white" }} />}
      /> */}
      {control}
    </div>
  );
};

const TexturePropertyEditor = (props: {
  property: string;
  texture: THREE.Texture;
  onChange: (value: THREE.Texture) => void;
}) => {
  const { property, texture, onChange } = props;

  const wrapType = texture?.type || constants.textures.ClampToEdge;
  const wrapX = texture?.repeat?.x || 1; // 9
  const wrapY = texture?.repeat?.y || 1;
  const flipY = texture?.flipY || false;
  // texture.center = new THREE.Vector2(0.5, 0.5); // todo implement center
  // texture.rotation = Math.PI; // todo implement rotation
  // texture.premultiplyAlpha = !premultiplyAlpha; // todo implement premultiplyAlpha

  const [advancedProps, setAdvancedProps] = useState(false);

  const onTextureChange = (params: {
    texture?: any;
    wrapType?: any;
    wrapX?: number;
    wrapY?: number;
    flipY?: boolean;
  }) => {
    const t: any = params.texture || texture;
    if (!t) {
      console.log("❌ invalid texture");
      return;
    }
    console.log("changing:", params);
    t.wrapS = params.wrapType ?? wrapType;
    t.wrapT = params.wrapType ?? wrapType;
    t.repeat.set(params.wrapX ?? wrapX, params.wrapY || wrapY);
    t.flipY = params.flipY ?? flipY;
    t.needsUpdate = true;
    onChange(t);
  };

  return (
    <div>
      <PropertyLayout
        title={property === "map" ? "diffuseMap" : property}
        onTitleClick={() => setAdvancedProps((p) => !p)}
        control={
          <div css={{ margin: "4px 8px" }} onClick={() => console.log(18, texture)}>
            <DropFiles
              onDropFiles={(files) => {
                if (!files || !files.length) return;

                // const texture = textureLoader.load(files[0]);

                let userImageURL = URL.createObjectURL(files[0]);
                let loader = new THREE.TextureLoader();
                loader.setCrossOrigin("");
                let texture = loader.load(
                  userImageURL,
                  (texture) => {
                    console.log(19, texture);
                    // texture.encoding = 3001;
                    texture.encoding = THREE.sRGBEncoding;
                    // lightTexture.color.convertSRGBToLinear();
                    //!--
                    // texture.internalFormat = "R32UI";
                    // texture.format = THREE.RedIntegerFormat;
                    // texture.type = THREE.UnsignedIntType;
                    onTextureChange({ texture });
                  },
                  (progress) => {},
                  (error) => {
                    console.log("❌ texture upload:", error);
                    MLDialog.showSnackbar("Error loading texture");
                  }
                );
                // update texture
                // shader.uniforms.texture1.value = texture;
              }}
              children={<ImagePreview src={texture?.image?.src || texture?.image} />}
            />
          </div>
        }
      />
      {/* ADDITIONAL TEXTURE OPTIONS */}
      {texture && advancedProps && (
        <div
          css={{
            marginLeft: 16,
            padding: 8,
            paddingRight: 0,
            marginBottom: 8,
            border: "1px solid rgba(255,255,255,0.5)",
            borderRight: 0,
            borderTop: 0,
          }}
        >
          <PropertyLayout
            title="type"
            control={
              <select
                css={{ width: largeFieldWidth }}
                value={(texture as any)?.type}
                onChange={(e) => {
                  const value: any = e.target.value;
                  const textureType = (constants as any).textures[value];
                  console.log(41, textureType, THREE.RepeatWrapping);
                  texture.wrapS = textureType;
                  texture.wrapT = textureType;
                  onChange(texture);
                }}
              >
                {Object.keys(constants.textures).map((key) => (
                  <option key={key} value={key} children={key} />
                ))}
              </select>
            }
          />

          <PropertyLayout
            title="flipY"
            control={
              <Checkbox
                css={{ margin: "4px 2px" }}
                checked={texture.flipY || false}
                onChange={(e) => {
                  onTextureChange({ flipY: e.target.checked || false });
                }}
              />
            }
          />
          <PropertyLayout
            title="wrapS"
            control={
              <NumberedSlider
                min={1}
                max={100}
                step={1}
                value={wrapX}
                onChange={(value) => onTextureChange({ wrapX: value })}
              />
            }
          />
          <PropertyLayout
            title="wrapT"
            control={
              <NumberedSlider
                min={1}
                max={100}
                step={1}
                value={wrapY}
                onChange={(value) => onTextureChange({ wrapY: value })}
              />
            }
          />
        </div>
      )}
    </div>
  );
};

export default MaterialEditor;

//! ---
//todo - generic
/*
function guiMaterial(gui, mesh, material, geometry) {
    const folder = gui.addFolder("THREE.Material");
    folder.open();
  
    folder.add(material, "transparent");
    folder.add(material, "opacity", 0, 1).step(0.01);
    // folder.add( material, 'blending', constants.blendingMode );
    // folder.add( material, 'blendSrc', constants.destinationFactors );
    // folder.add( material, 'blendDst', constants.destinationFactors );
    // folder.add( material, 'blendEquation', constants.equations );
    folder.add(material, "depthTest");
    folder.add(material, "depthWrite");
    // folder.add( material, 'polygonOffset' );
    // folder.add( material, 'polygonOffsetFactor' );
    // folder.add( material, 'polygonOffsetUnits' );
    folder.add(material, "alphaTest", 0, 1).step(0.01).onChange(needsUpdate(material, geometry));
    folder.add(material, "visible");
    folder.add(material, "side", constants.side).onChange(needsUpdate(material, geometry));
  
    console.log(99,folder)
    
  }*/

const GenericMaterialProps = ["transparent", "opacity", "depthTest", "depthWrite", "alphaTest", "visible", "side"];

export const SupportedMaterials = {
  // "MeshBasicMaterial", //!
  // "MeshDepthMaterial", //!
  // "MeshNormalMaterial", //!
  // "LineBasicMaterial", //!
  // "MeshLambertMaterial", //!
  // "MeshMatcapMaterial", //! (to do - implement texture)
  // "MeshPhongMaterial", //!
  // "MeshToonMaterial", //!
  // "MeshStandardMaterial", //! (to do - metalnessMap)
  // "MeshPhysicalMaterial", //! (to do - metalnessMap)

  MeshBasicMaterial: {
    create: (color: number) => new THREE.MeshBasicMaterial({ color }),
    props: [
      "color",
      "wireframe",
      "vertexColors",
      "fog",
      "envMaps",
      "map",
      "alphaMap",
      "combine",
      "reflectivity",
      "refractionRatio",
    ],
  },

  MeshDepthMaterial: {
    create: () => new THREE.MeshDepthMaterial(),
    props: ["wireframe", "alphaMap"],
  },

  MeshNormalMaterial: {
    create: () => new THREE.MeshNormalMaterial(),
    props: ["flatShading", "wireframe"],
  },

  LineBasicMaterial: {
    create: (color: number) => new THREE.LineBasicMaterial({ color }),
    props: ["color", "linewidth", "linecap", "linejoin", "vertexColors", "fog"],
  },

  MeshLambertMaterial: {
    create: (color: number) => new THREE.MeshLambertMaterial({ color }),
    props: [
      "color",
      "wireframe",
      "vertexColors",
      "fog",
      "envMaps",
      "map",
      "alphaMap",
      "combine",
      "reflectivity",
      "refractionRatio",
    ],
  },

  // MeshMatcapMaterial: {
  //   create: () => new THREE.MeshMatcapMaterial({ matcap: matcaps.porcelainWhite }), //todo - matcap
  //   props: ["color", "flatShading", "matcap", "alphaMap"],
  // },

  MeshPhongMaterial: {
    create: (color: number) => new THREE.MeshPhongMaterial({ color }),
    props: [
      "color",
      "emissive",
      "specular",
      "shininess",
      "flatShading",
      "wireframe",
      "vertexColors",
      "fog",
      "envMaps",
      "map",
      "alphaMap",
      "combine",
      "reflectivity",
      "refractionRatio",
    ],
  },

  MeshToonMaterial: {
    create: (color: number) => new THREE.MeshToonMaterial({ color, gradientMap: gradientMaps.threeTone }),
    props: ["color", "map", "gradientMap", "alphaMap"],
  },

  MeshStandardMaterial: {
    create: (color: number) => new THREE.MeshStandardMaterial({ color }),
    props: [
      "color",
      "emissive",
      "roughness",
      "metalness",
      "flatShading",
      "wireframe",
      "vertexColors",
      "fog",
      "envMaps",
      "map",
      "roughnessMap",
      "alphaMap",
    ],
  },

  MeshPhysicalMaterial: {
    create: (color: number) => new THREE.MeshPhysicalMaterial({ color }),
    props: [
      "color",
      "emissive",
      "roughness",
      "metalness",
      "reflectivity",
      "clearcoat",
      "clearcoatRoughness",
      "flatShading",
      "wireframe",
      "vertexColors",
      "fog",
      "envMaps",
      "map",
      "roughnessMap",
      "alphaMap",
    ],
  },
};

const constants = {
  combine: {
    MultiplyOperation: THREE.MultiplyOperation,
    MixOperation: THREE.MixOperation,
    AddOperation: THREE.AddOperation,
  },

  textures: {
    ClampToEdge: THREE.ClampToEdgeWrapping,
    Repeat: THREE.RepeatWrapping,
    MirroredRepeat: THREE.MirroredRepeatWrapping,
  },

  side: {
    FrontSide: THREE.FrontSide,
    BackSide: THREE.BackSide,
    DoubleSide: THREE.DoubleSide,
  },

  blendingMode: {
    NoBlending: THREE.NoBlending,
    NormalBlending: THREE.NormalBlending,
    AdditiveBlending: THREE.AdditiveBlending,
    SubtractiveBlending: THREE.SubtractiveBlending,
    MultiplyBlending: THREE.MultiplyBlending,
    CustomBlending: THREE.CustomBlending,
  },

  equations: {
    AddEquation: THREE.AddEquation,
    SubtractEquation: THREE.SubtractEquation,
    ReverseSubtractEquation: THREE.ReverseSubtractEquation,
  },

  destinationFactors: {
    ZeroFactor: THREE.ZeroFactor,
    OneFactor: THREE.OneFactor,
    SrcColorFactor: THREE.SrcColorFactor,
    OneMinusSrcColorFactor: THREE.OneMinusSrcColorFactor,
    SrcAlphaFactor: THREE.SrcAlphaFactor,
    OneMinusSrcAlphaFactor: THREE.OneMinusSrcAlphaFactor,
    DstAlphaFactor: THREE.DstAlphaFactor,
    OneMinusDstAlphaFactor: THREE.OneMinusDstAlphaFactor,
  },

  sourceFactors: {
    DstColorFactor: THREE.DstColorFactor,
    OneMinusDstColorFactor: THREE.OneMinusDstColorFactor,
    SrcAlphaSaturateFactor: THREE.SrcAlphaSaturateFactor,
  },
};

const ImagePreview = (props: { src: any }) => {
  const [src, setSrc] = useState<any>();

  const initImage = async () => {
    if (!props.src) return null;
    if (!(props.src instanceof ImageBitmap)) return props.src?.src;

    //todo wip tiz canvas preview (ImageBitmap)

    // const canvas = document.createElement("canvas");
    // // resize it to the size of our ImageBitmap
    // canvas.width = props.src.width;
    // canvas.height = props.src.height;
    // // try to get a bitmaprenderer context
    // const ctx = canvas.getContext("bitmaprenderer");
    // if (!ctx) return null;
    // ctx.transferFromImageBitmap(props.src);
    // return new Promise((res, rej) => {
    //   canvas.toBlob((blob) => {
    //     if (!blob) rej("error");
    //     res(blob);
    //   });
    // });
  };

  useEffect(() => {
    initImage();
  }, []);

  useEffect(() => {
    if (!props.src) {
      setSrc(undefined);
      return;
    }
    initImage();
  }, [props.src]);

  return (
    <div
      css={{
        width: 32,
        height: 32,
        fontSize: 20,
        background: props.src ? "#0088aa" : "#ffffff66",
        border: "1px solid white",
        cursor: "pointer",
        backgroundImage: src ? `url(${src})` : undefined,
        backgroundSize: "cover",
        textAlign: "center",
        lineHeight: "32px",
      }}
      children={props.src ? "✔" : "✖"}
    />
  );
};
