import { AxiosError } from "axios";
import _ from "lodash";
import { IForm } from "./interfaces";

export const calculatePercentageForm = (form: IForm, activeStepName?: string) => {
  const steps = _.flatten(form?.sections?.map((s) => s.forms.map((form) => form.stepname)));

  let currentStepIndex = -1;
  if (activeStepName) {
    currentStepIndex = steps.findIndex((x) => x === activeStepName);
  }

  if (currentStepIndex === -1) return 0;

  const totalSteps = steps.length - 1;
  let calcPercentage = (currentStepIndex / totalSteps) * 100;

  if (calcPercentage < 0) calcPercentage = 0;

  return Math.round(calcPercentage);
};

export const defaultStorekeyLocalStorage = "bonusXState";

export function getCurrentStateFromLocalStorage(initialState?: any) {
  const locationToCheck = defaultStorekeyLocalStorage;

  const persistedValue = localStorage.getItem(locationToCheck);

  if (!persistedValue) return initialState;

  const parsedState = JSON.parse(persistedValue);

  return { ...parsedState, forms: initialState?.forms || parsedState?.forms, header: initialState?.header };
}

export const apiErrorParser = (axiosError: AxiosError) => {
  const resp = axiosError.response?.data;
  let error = "genericError";

  if (resp?.errors) {
    error = resp?.errors?.[0]?.param || "genericError";
  } else {
    error = resp?.message || "genericError";
  }

  return error;
};

export function getFullName(person?: { name?: string; surname?: string }) {
  return (person?.name || "") + " " + (person?.surname || "");
}

export const CurrencyFormatter = new Intl.NumberFormat(undefined, {
  style: "currency",
  currency: "EUR",

  // These options are needed to round to whole numbers if that's what you want.
  //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
  //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
});

// export const apiErrorParser =  async <T extends Object> (api: Promise<AxiosResponse<T>>) => {
//   return new Promise((resolve) => {
//    api
//       .then((res) => {
//         if (res.status >= 300 || res.status < 200) {
//           res.json().then((bodyErrors) => {
//             if (bodyErrors?.errors) {
//               resolve({ error: bodyErrors?.errors?.[0]?.param || "genericError" });
//             } else {
//               resolve({ error: bodyErrors?.message || "genericError" });
//             }
//           });
//         } else {
//           resolve(res.json());
//         }
//       })
//       .catch(console.log);
//   });
// };

export function backendMediaUrlParser(fileUrl?: string, download?: boolean) {
  if (!fileUrl) return undefined;
  if (fileUrl.includes("blob:")) return fileUrl;
  return process.env.REACT_APP_MAIN_URL + "/media/" + fileUrl;
}

export const DocumentCategory = [
  { id: 0, value: "Personali" },
  { id: 1, value: "Redditi e lavoro" },
  { id: 2, value: "Anziani" },
  { id: 3, value: "Conti e titoli " },
  { id: 4, value: "Veicoli" },
  { id: 5, value: "Certificati e ricevute" },
  { id: 6, value: "ISEE" },
  { id: 7, value: "Altro" },
];

export const ServiceCategory = [
  { id: 0, value: "Famiglia" },
  { id: 1, value: "Giovani" },
  { id: 2, value: "Anziani" },
  { id: 3, value: "Studenti " },
  { id: 4, value: "Casa" },
  { id: 5, value: "Veicoli e trasporti" },
  { id: 6, value: "Imprese" },
  { id: 7, value: "Lavoratori e disoccupati" },
  { id: 8, value: "Pensionati" },
  { id: 9, value: "Salute" },
  { id: 10, value: "Tempo libero" },
  { id: 11, value: "Servizi Accessori" },
];

export const SelectMainActivityOptions = [
  { id: 1, value: "Lavoratore dipendente a tempo indeterminato" },
  { id: 2, value: "Lavoratore dipendente a tempo determinato o con contratto di apprendistato" },
  { id: 3, value: "Lavoratore con contratto di somministrazione (“interinale”)" },
  {
    id: 4,
    value:
      "Lavoratore o disoccupato con sostegno al reddito (cassa integrazione, ASPI, lavori socialmente utili, ecc.) ",
  },
  { id: 5, value: "Lavoratore parasubordinato (coll. a progetto o Co.Co.Co)" },
  { id: 6, value: "Lavoro accessorio (voucher), occasionale, tirocini/stage, ecc." },
  { id: 7, value: "Lavoratore autonomo, libero professionista, imprenditore" },
  { id: 8, value: "Non occupato" },
  { id: 9, value: "Pensionato" },
  { id: 10, value: "Casalinga" },
  { id: 11, value: "Studente" },
  { id: 12, value: "Altro" },
];

export const SelectParentOptions = [
  { id: 1, value: "Coniuge" },
  { id: 2, value: "Unito civilmente" },
  { id: 3, value: "Figlio/a" },
  { id: 4, value: "Minore in affido preadottivo" },
  { id: 5, value: "Genitore" },
  { id: 6, value: "Sorella/Fratello" },
  { id: 7, value: "Nonno/a" },
  { id: 8, value: "Nipote" },
  { id: 9, value: "Zio/a" },
  { id: 10, value: "Cugino/a" },
  { id: 11, value: "Altro" },
];

/**
 * Trims the string to {max} value and adds to the end "..."
 *
 * @param {string} value
 * @param {number} max
 */
export function trimStringToMax(value: string | undefined, max: number) {
  if (!value) return "";
  if (value.length < max) {
    return value;
  }

  return value.substr(0, max) + "...";
}
