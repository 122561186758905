/* eslint-disable react-hooks/exhaustive-deps */
/** @jsxImportSource @emotion/react */

import { MenuItem, Select } from "@material-ui/core";
import { useEffect, useRef, useState } from "react";
import { Mesh } from "three";
import MaterialEditor, { SupportedMaterials } from "./MaterialEditor";
import * as DAT from "dat.gui";

const getMaterial = (mesh: Mesh) => (Array.isArray(mesh.material) ? mesh.material : [mesh.material])[0];

const EditorWorkbench = (props: {
  viewer: any;
  children: any;
  meshes: Mesh[];
  highlightMesh: (mesh?: Mesh) => void;
}) => {
  const { viewer, meshes, highlightMesh } = props;

  const [selectedMesh, setSelectedMesh] = useState(-1);

  useEffect(() => {
    return () => {
      setSelectedMesh(-1);
    };
  }, []);

  function setWireframe(index: number, visibile: boolean) {
    const mesh = meshes?.[index];
    if (!mesh) return;
    const mats = Array.isArray(mesh.material) ? mesh.material : [mesh.material];
    mats.forEach((m: any) => (m.wireframe = visibile));
  }

  function onHighlightMesh(mesh?: Mesh) {
    highlightMesh(mesh);
  }

  function applyNewMaterialToMesh(mesh: Mesh, type: string) {
    const _mesh = mesh as any;

    // const material = createMeshMaterial(type);
    const material = (SupportedMaterials as any)[type].create(); //getMaterial(mesh)?.color??'');
    _mesh.material = material;

    onHighlightMesh(mesh);
  }

  return (
    <div
      css={{
        position: "absolute",
        top: 0,
        left: 15,
        padding: 8,
        background: "black",
        color: "white",
        width: 240,
        zIndex: 10,
        maxHeight: "100vh",
        overflowY: "scroll",
        overflowX: "hidden",
        paddingTop: 60,
      }}
    >
      {!meshes?.length
        ? "No mesh found"
        : meshes.map((mesh, index) => {
            const selected = index == selectedMesh;
            return (
              <div
                key={index}
                css={{
                  padding: 8,
                  overflow: "hidden",
                  cursor: "pointer",
                  userSelect: "none",
                  opacity: selected ? 1 : 0.7,
                  fontWeight: selected ? "bold" : undefined,
                }}
                children={(selected ? "• " : "") + mesh?.name}
                onClick={() => {
                  if (selected) {
                    setSelectedMesh(-1);
                    onHighlightMesh(undefined);
                    return;
                  }

                  // setWireframe(index, true);
                  // setWireframe(selectedMesh, false);
                  setSelectedMesh(index);
                  onHighlightMesh(meshes[index]);
                }}
              />
            );
          })}

      {meshes?.[selectedMesh] ? (
        <MaterialMeshEditor
          key={selectedMesh}
          mesh={meshes[selectedMesh]}
          onChange={(type) => applyNewMaterialToMesh(meshes[selectedMesh], type)}
        />
      ) : null}
    </div>
  );
};

export default EditorWorkbench;

const MaterialMeshEditor = (props: { mesh: Mesh; onChange: (newType: string) => void }) => {
  const { mesh, onChange } = props;

  const [materialType, setMaterialType] = useState(getMaterial(mesh).type);
  useEffect(() => setMaterialType(getMaterial(mesh).type), [mesh]);

  return (
    <div
      css={{
        borderTop: "1px solid white",
        // padding: 4,
        paddingTop: 12,
        marginTop: 16,
      }}
    >
      {/* <Select
        value={materialType}
        fullWidth
        variant="filled"
        css={{
          "svg, .MuiFilledInput-input": { color: "white" },
          background: "none",
          border: "1px solid rgba(255,255,255,0.2) !important",
          borderRadius: 0,
          "&.MuiFilledInput-root.Mui-focused:not(:focus)": { background: "none" },
          // padding: 0,
          // ":hover": { backgroundColor: "rgba(0, 0, 0, 0.2)" },
        }}
        onChange={(e: any) => {
          const type = e.target.value;
          onChange(type);
          setMaterialType(type);
        }}
      >
        {Object.keys(SupportedMaterials).map((x) => (
          <MenuItem key={x} value={x} children={x} />
        ))}
      </Select> */}

      <MaterialEditor
        key={materialType}
        material={getMaterial(mesh)}
        needsUpdate={(material) => {
          // const material = getMaterial(mesh) as any;
          material.vertexColors = material.vertexColors;
          console.log("number", material.side);
          material.side = parseInt(String(material.side)); //Ensure number
          material.needsUpdate = true;
          const geometry = mesh.geometry;
          if (geometry?.attributes?.position) geometry.attributes.position.needsUpdate = true;
          if (geometry?.attributes?.normal) geometry.attributes.normal.needsUpdate = true;
          if (geometry?.attributes?.color) geometry.attributes.color.needsUpdate = true;
        }}
      />
    </div>
  );
};
