import {
  Button,
  Collapse,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { AuthenticationApi } from "bonusx-api-main-manager";
import { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import NotVisibleIcon from "../../components/icons/not_visible";
import VisibleIcon from "../../components/icons/visible";
import { apiErrorParser } from "../../utils";
import { useApi } from "../../utils/api";
// import { fasterFetch } from "../src/utils";

function LoginPage() {
  const theme = useTheme();

  const history = useHistory<any>();

  const isMobile = useMediaQuery("@media only screen and (max-width:500px)");

  // const [redirectUri, setRedirectUri] = useState("");

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [errorMessageVisible, setErrorMessageVisible] = useState(false);

  const [visiblePassword, setVisibilePassword] = useState(false);

  // useEffect(() => {
  //   const REDIRECT_URI = process.env.NEXT_PUBLIC_HOST;
  //   // console.log("STARTUP", router.query, "REDIRECT", REDIRECT_URI);

  //   setRedirectUri((history?.query["back-url"] as string) || REDIRECT_URI);
  //   setScope((history?.query["scope"] as string) || "platform");
  // }, [history]);

  const authenticationApi = useApi(AuthenticationApi);

  return (
    <Grid
      container
      direction="column"
      justify="center"
      alignItems="center"
      style={{
        height: "100vh",
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        // height: "calc(100% - 40px)",
      }}
    >
      <Grid container direction="column" justify="center" alignItems="center">
        <img src="/images/login-illustration.png" width={185.54} alt="login" />

        <Typography
          children="Bentornato!"
          color="textPrimary"
          style={{
            fontSize: 20,
            fontWeight: "bold",
            marginTop: theme.spacing(2),
          }}
        />
        <Typography children="You've been missed!" style={{ fontFamily: "Karla", fontSize: 16, color: "#646874" }} />

        <TextField
          value={email}
          style={{ maxWidth: isMobile ? 280 : 300, marginTop: 30 }}
          fullWidth
          placeholder="E-mail"
          variant="outlined"
          onChange={(event) => {
            // setErrorMessage("");
            // setErrorMessageVisible(false);
            setEmail(event.target.value);
          }}
        />
        <TextField
          style={{
            marginTop: theme.spacing(2),
            maxWidth: isMobile ? 280 : 300,
          }}
          fullWidth
          variant="outlined"
          type={!visiblePassword ? "password" : "text"}
          name="password"
          placeholder="Password"
          value={password}
          onKeyPress={(ev) => {
            if (ev.key === "Enter" && !ev.shiftKey) {
              ev.preventDefault();
              invokeLoginApi();
            }
          }}
          onChange={(event) => {
            // setErrorMessage("");
            // setErrorMessageVisible(false);
            setPassword(event.target.value);
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={(e) => {
                    setVisibilePassword((value) => !value);
                  }}
                  edge="end"
                  style={{ color: "black" }}
                >
                  {visiblePassword ? <VisibleIcon /> : <NotVisibleIcon />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <Collapse in={Boolean(errorMessageVisible)}>
          <Typography
            children={errorMessage}
            style={{
              marginTop: 16,
              color: theme.palette.warning.main,
              textAlign: "center",
            }}
          />
        </Collapse>

        <Button
          fullWidth
          style={{
            maxWidth: isMobile ? 280 : 300,
            marginTop: theme.spacing(2),
          }}
          variant="contained"
          color="primary"
          children="ACCEDI"
          onClick={() => {
            invokeLoginApi();
          }}
        />

        <Link
          to="/register"
          style={{
            color: theme.palette.text.secondary,
            textDecoration: "none",
          }}
        >
          <div
            style={{
              fontSize: 14,
              marginTop: theme.spacing(2),
              cursor: "pointer",
            }}
          >
            Non hai un account? <span style={{ color: theme.palette.primary.main, fontSize: 16 }}>Registrati ora</span>
          </div>
        </Link>
      </Grid>
    </Grid>
  );

  async function invokeLoginApi() {
    if (email === "" || password === "") {
      setErrorMessage("Devi compilare tutti i campi per continuare");
      toggleErrorMessageVisible(true);
      return;
    } else {
      // setErrorMessage("");
      toggleErrorMessageVisible(false);
    }

    if (errorMessage) setErrorMessage(null);
    try {
      const { data } = await authenticationApi.login({
        email,
        password,
      });

      console.log(data);

      if (data?.redisKey) {
        localStorage.clear();
        localStorage.setItem("ability-rules", JSON.stringify(data?.abilityRules || []));
        window.location.href = history?.location?.state?.from?.pathname || "/dashboard";
      }
    } catch (axiosError) {
      const error = apiErrorParser(axiosError);

      console.log("ERROR", error);

      setTimeout(
        () => {
          if (error) {
            switch (error) {
              case "email":
                setErrorMessage("Inserisci una mail valida");
                toggleErrorMessageVisible(true);
                break;
              case "password":
                setErrorMessage("Inserisci una password valida (almeno 5 caratteri)");
                toggleErrorMessageVisible(true);
                break;
              case "invalidEmail":
                setErrorMessage("Mail non registrata");
                toggleErrorMessageVisible(true);
                break;
              case "invalidPassword":
                setErrorMessage("Password errata, riprova!");
                toggleErrorMessageVisible(true);
                break;
              default:
                setErrorMessage(null);
                toggleErrorMessageVisible(false);
            }

            return;
          }
        },
        errorMessage ? 500 : 0
      );
    }
  }

  function toggleErrorMessageVisible(visible: any) {
    setTimeout(
      () => {
        setErrorMessageVisible(visible);
      },
      errorMessage ? 500 : 0
    );
  }
}

export default LoginPage;
