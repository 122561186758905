/* eslint-disable react-hooks/exhaustive-deps */
/** @jsxImportSource @emotion/react */

import { getLuminance, Popover, rgbToHex } from "@material-ui/core";
import * as THREE from "three";
import React, { useState, useRef } from "react";
import { ChromePicker, ColorResult } from "react-color";

const ColorPicker = (props: { defaultValue?: THREE.Color; onChange: (color: THREE.Color) => void }) => {
  const [open, setOpen] = useState(false);
  const [color, setColor] = useState(() => {
    if (!props.defaultValue) return "#049ef4";
    return "#" + props.defaultValue.getHexString();
  });
  const ref = useRef<any>();
  const lastUpdateMillis = useRef(0);

  const onColor = (colorItem: ColorResult, complete?: boolean) => {
    // console.log(55, colorItem);
    setColor(colorItem.hex);

    const now = new Date().getTime();
    if (!complete && now - lastUpdateMillis.current < 80) return;
    lastUpdateMillis.current = now;

    // props.onChange({
    //   r: colorItem.rgb.r / 255,
    //   g: colorItem.rgb.g / 255,
    //   b: colorItem.rgb.b / 255,
    // });
    props.onChange(new THREE.Color(colorItem.rgb.r / 255, colorItem.rgb.g / 255, colorItem.rgb.b / 255));
  };

  return (
    <React.Fragment>
      <div
        ref={ref}
        css={{
          cursor: "pointer",
          minWidth: 30,
          minHeight: 20,
          backgroundColor: color,
          border: "1px solid white",
          boxShadow: "0 0 1px black",
          color: getLuminance(color) > 0.5 ? "black" : "white",
          textAlign: "center",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        children={color}
        onClick={() => setOpen(true)}
      />

      <Popover open={open} onClose={() => setOpen(false)} anchorEl={ref.current}>
        <ChromePicker
          color={color}
          onChange={(color) => onColor(color)}
          onChangeComplete={(color) => onColor(color, true)}
          disableAlpha
        />
      </Popover>
    </React.Fragment>
  );
};

export default ColorPicker;
