/* eslint-disable react-hooks/exhaustive-deps */
/** @jsxImportSource @emotion/react */

import { Slider, TextField } from "@material-ui/core";
import { useEffect, useState } from "react";

const NumberedSlider = (props: {
  defaultValue?: number;
  value?: number;
  onChange: (value: number) => void;
  min?: number;
  max?: number;
  step?: number;
}) => {
  const min = props.min || 0;
  const max = props.max || 1;
  const step = props.step || 0.01;
  const [val, setVal] = useState(props.defaultValue || 0);

  useEffect(() => {
    setVal(props.value || 0);
  }, [props.value]);

  useEffect(() => {
    setVal(props.defaultValue || 0);
  }, [props.defaultValue]);

  return (
    <div css={{ flex: 2, display: "flex", flexDirection: "row", alignItems: "center" }}>
      <div css={{ flex: 1 }}>
        <Slider
          // defaultValue={props.defaultValue}
          value={val}
          onChange={(e, value) => {
            const number = Number(value);
            setVal(number);
            props.onChange(number);
          }}
          min={min}
          max={max}
          step={step}
        />
      </div>
      <input
        css={{
          padding: 0,
          borderRadius: 0,
          marginLeft: 8,
          width: 30,
        }}
        value={val}
        onChange={(e) => {
          console.log(91, e.target.value);
          if (e.target.value.endsWith(".")) return;
          let n = parseFloat(e.target.value || "0") || 0;
          n = Math.min(max, Math.max(min, n));
          setVal(n);
          props.onChange(n);
        }}
      />
    </div>
  );
};

export default NumberedSlider;
