import { CheckboxProps, Grid, useTheme } from "@material-ui/core";

interface BetterCheckBoxProps extends Omit<CheckboxProps, "onChange"> {
  onChange?: (value?: boolean) => void;
}

function BetterCheckBox(props: BetterCheckBoxProps) {
  const theme = useTheme();

  let mainColor = theme.palette.secondary.main;

  if (props.color === "primary") mainColor = theme.palette.primary.main;

  let size = 20;
  let widthCheckBox = 10;
  let heightCheckBox = 9;
  if (props?.size === "medium") {
    size = 32;
    widthCheckBox = 14;
    heightCheckBox = 11;
  }

  return (
    <Grid
      container
      style={{
        backgroundColor: props.checked ? mainColor : "white",
        width: size,
        height: size,
        boxShadow: "0px 4px 8px rgba(28, 37, 44, 0.08)",
        borderRadius: 5,
        border: `1.5px solid ${props.checked ? mainColor : "#E7E7E7"}`,
        transition: theme.transitions.create(["background-color", "border"]),
        cursor: "pointer",
      }}
      alignItems="center"
      justify="center"
      onClick={() => props?.onChange?.()}
    >
      {props?.checked ? (
        <svg
          width={widthCheckBox}
          height={heightCheckBox}
          viewBox={`0 0 ${widthCheckBox} ${heightCheckBox}`}
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d={props.size === "medium" ? "M13 1L4.75 10L1 5.90909" : "M8.75 1.67688L3.59375 7.30188L1.25 4.74506"}
            stroke="white"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      ) : (
        <div />
      )}
    </Grid>
  );
}

export default BetterCheckBox;
