import { Grid, useTheme } from "@material-ui/core";

export interface IIcon {
  size?: number;
  color?: string;
  highlighted?: boolean;
  HighlightProps?: {
    soft?: boolean;
    backgroundColor?: string;
    forceRegularSize?: boolean;
  };
}

interface ITemplateIcon {
  icon: JSX.Element;
  iconProps: IIcon;
}

const TemplateIcon = (props: ITemplateIcon) => {
  const theme = useTheme();
  const {
    size = 24,
    color = theme.palette.primary.main,
    highlighted = false,
    HighlightProps: HighliteProps = { soft: true }
  } = props.iconProps;

  if (props?.iconProps?.HighlightProps && !props?.iconProps?.highlighted) {
    console.warn('You may have forgotten to set an "highlited" prop to icon.');
  }

  if (
    props?.iconProps?.HighlightProps?.soft === false &&
    !props?.iconProps?.HighlightProps?.backgroundColor
  ) {
    console.error(
      "You must set a background color to highlighted icon if you set it as not soft"
    );
  }

  return highlighted ? (
    <div style={{ display: "inline-block" }}>
      <Grid
        container
        justify="center"
        alignItems="center"
        children={props.icon}
        style={{
          width: size,
          height: size,
          borderRadius: size,
          backgroundColor: Boolean(HighliteProps.backgroundColor)
            ? HighliteProps.backgroundColor
            : HighliteProps.soft
            ? "#F3F5F8"
            : color
        }}
      />
    </div>
  ) : (
    props.icon
  );
};

export const calculateHighlightedSize = (size: number) => (size * 75) / 100;

export default TemplateIcon;
