/* eslint-disable react-hooks/exhaustive-deps */
/** @jsxImportSource @emotion/react */

import { useState } from "react";

const SmartEditor = (props: { object: any; onUpdate: (object: any) => void }) => {
  const { object, onUpdate } = props;
  const [open, setOpen] = useState(false);
  return (
    <div css={{ fontSize: 12 }}>
      <div
        children="Smart Editor"
        css={{ background: "blue", padding: 4, margin: 4, marginTop: 16, cursor: "pointer" }}
        onClick={(e) => setOpen((o) => !o)}
      />
      {open &&
        Object.keys(object || {}).map((key) => {
          return (
            <div
              key={key}
              onClick={() => console.log("pre", key, object[key])}
              css={{ display: "flex", flexDirection: "row", marginBottom: 8, alignItems: "center" }}
            >
              <div css={{ flex: 1 }}>
                <div children={key} />
                <div children={typeof object[key]} css={{ opacity: 0.6 }} />
              </div>

              <div
                children={String(object[key])}
                css={{ cursor: "pointer", minWidth: 10, minHeight: 10 }}
                onClick={() => {
                  setTimeout(() => {
                    const val = prompt("❌ super risky action\n\nNew value for [" + key + "]");
                    if (val != undefined) {
                      object[key] =
                        val == "null"
                          ? undefined
                          : val == "true"
                          ? true
                          : val == "false"
                          ? false
                          : isNaN(parseInt(val))
                          ? parseInt(val)
                          : isNaN(parseFloat(val))
                          ? parseFloat(val)
                          : val;
                      onUpdate(object);
                      console.log("post", key, object[key]);
                    }
                  }, 5);
                }}
              />
            </div>
          );
        })}
    </div>
  );
};

export default SmartEditor;
