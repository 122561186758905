/* eslint-disable react-hooks/exhaustive-deps */
/** @jsxImportSource @emotion/react */

import React from "react";
import { Viewer } from "./Viewer";

class ThreeViewer extends React.Component {
  state = {
    sceneReady: false,
  };

  render() {
    return (
      <div
        ref={(r) => (this.element = r)}
        style={{
          position: "fixed",
          left: 0,
          top: 0,
          right: 0,
          bottom: 0,
        }}
      />
    );
  }

  componentDidMount() {
    this.viewer = null;
    this.viewerEl = null;

    this.options = {
      kiosk: false,
      model: "",
      preset: "",
      cameraPosition: null,
    };

    if (this.props.model) {
      this.view(this.props.model, "", new Map());
    }
  }

  /**
   * Sets up the view manager.
   * @return {Viewer}
   */
  createViewer() {
    this.viewerEl = document.createElement("div");
    this.viewerEl.classList.add("viewer");
    this.element.innerHTML = "";
    this.element.appendChild(this.viewerEl);
    this.viewer = new Viewer(this.viewerEl, this.options);
    return this.viewer;
  }

  /**
   * Loads a fileset provided by user action.
   * @param  {Map<string, File>} fileMap
   */
  load(fileMap) {
    let rootFile;
    let rootPath;
    Array.from(fileMap).forEach(([path, file]) => {
      if (file.name.match(/\.(gltf|glb)$/)) {
        rootFile = file;
        rootPath = path.replace(file.name, "");
      }
    });

    if (!rootFile) {
      this.onError("No .gltf or .glb asset found.");
    }

    this.modelFile = rootFile;

    this.view(rootFile, rootPath, fileMap);
  }

  /**
   * Passes a model to the viewer, given file and resources.
   * @param  {File|string} rootFile
   * @param  {string} rootPath
   * @param  {Map<string, File>} fileMap
   */
  view(rootFile, rootPath, fileMap) {
    this.setState({ sceneReady: false });
    if (this.viewer) this.viewer.clear();

    const viewer = this.viewer || this.createViewer();

    const fileURL = typeof rootFile === "string" ? rootFile : URL.createObjectURL(rootFile);

    const cleanup = () => {
      if (typeof rootFile === "object") URL.revokeObjectURL(fileURL);
    };

    viewer
      .load(fileURL, rootPath, fileMap)
      .catch((e) => this.onError(e))
      .then((gltf) => {
        console.log("🟩 loaded", gltf);
        this.setState({ sceneReady: true });

        if (!this.options.kiosk) {
          // this.validationCtrl.validate(fileURL, rootPath, fileMap, gltf); //! validator
        }
        cleanup();
      });
  }

  /**
   * @param  {Error} error
   */
  onError(error) {
    let message = (error || {}).message || error.toString();
    if (message.match(/ProgressEvent/)) {
      message = "Unable to retrieve this file. Check JS console and browser network tab.";
    } else if (message.match(/Unexpected token/)) {
      message = `Unable to parse file content. Verify that this file is valid. Error: "${message}"`;
    } else if (error && error.target && error.target instanceof Image) {
      message = "Missing texture: " + error.target.src.split("/").pop();
    }
    window.alert(message);
    console.error(error);
  }
}

export default ThreeViewer;
