/** @jsxImportSource @emotion/react */

import { Button, Grid, Typography } from "@material-ui/core";
import { useApi } from "../../utils/api";

function EmailVerificationSentPage() {
  const emailApi = useApi(EmailApi);

  return (
    <Grid
      container
      alignItems="center"
      direction="column"
      css={{
        paddingLeft: 10,
        paddingRight: 10,
        paddingTop: 100,
        "@media only screen and (max-width:500px)": {
          paddingTop: 20,
        },
      }}
    >
      {renderimage()}
      <Typography variant="h3" children="Ti abbiamo inviato un e-mail" css={{ marginTop: 20 }} />
      <Typography
        children="Per creare il tuo profilo è necessario verificare la tua email. Clicca sul link per essere reinderizzato al tuo profilo. Se l’email non ti dovesse arrivare, controlla la cartella SPAM o aspetta 5 minuti. Altrimenti richiedi l’invio di una nuova e-mail."
        css={{ marginTop: 20, maxWidth: 510 }}
      />

      {renderButton()}
    </Grid>
  );

  function renderButton() {
    return (
      <Button
        variant="outlined"
        children="Invia una nuova e-mail"
        color="primary"
        css={{
          textTransform: "none",
          marginTop: 20,
          fontFamily: "Nunito sans",
          fontWeight: "normal",
          width: 330,
          maxWidth: "100%",
        }}
        onClick={() => {
          // emailApi
          //   .sendVerificationEmail()
          //   .then((response) => {
          //     MLDialog.showSnackbar("Email inviata!", { variant: "success" });
          //   })
          //   .catch((error) => {
          //     console.log(apiErrorParser(error));
          //     MLDialog.showSnackbar("Qualcosa è andato storto", { variant: "error" });
          //   });
        }}
      />
    );
  }

  function renderimage() {
    return (
      <img
        src="/images/email-verification-sent.png"
        width="260px"
        css={{
          "@media only screen and (max-width:500px)": {
            width: 180,
          },
        }}
        alt="verification-sent-email"
      />
    );
  }
}

export default EmailVerificationSentPage;
