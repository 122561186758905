/* eslint-disable react-hooks/exhaustive-deps */
/** @jsxImportSource @emotion/react */

import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CardMedia,
  CircularProgress,
  Container,
  fade,
  Grid,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { AssetApi, FileApi, Project, ProjectApi } from "bonusx-api-main-manager";
import { useRef } from "react";
import { useHistory, useRouteMatch } from "react-router";
import MLDialog from "../../components/poppers";
import { apiErrorParser, backendMediaUrlParser } from "../../utils";
import { useApi, useFetch } from "../../utils/api";

function Projects3DPage(props: {}) {
  const { path } = useRouteMatch();
  const history = useHistory();

  //! RAMIFICAZIONI delle API
  const projectApi = useApi(ProjectApi);
  const assetApi = useApi(AssetApi);
  const fileApi = useApi(FileApi);

  const { data: projects, loading, revalidate } = useFetch(projectApi.getProjects);
  console.log("🔹 projects", projects);

  const onRequestDelete = (project: Project) => {
    if (window.confirm("Confirm deleting project named:" + project.name)) {
      projectApi
        .deleteProject(project.id!)
        .then(() => {
          revalidate();
          MLDialog.showSnackbar("Projecet deleted", { variant: "success" });
        })
        .catch((error) => MLDialog.showSnackbar(apiErrorParser(error), { variant: "error" }));
    }
  };

  const getEmbedUrl = async (project: Project) => {
    try {
      const assetId = project.assets![0].id!;
      const asset = (await assetApi.getAssetById(assetId)).data;
      //@ts-ignore
      const file = asset.files.sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt))[0];
      const opaque = file.id + asset.id + project.id;
      // const url = process.env.REACT_APP_MAIN_URL + "/3d-viewer/" + opaque;
      const isDevelopment = (window?.location?.href || "").includes("localhost.mabiloft.com:3000");
      const baseUrl = isDevelopment ? "https://localhost.mabiloft.com:3000" : "https://clear-3d.mabiloft.com"; //process.env.REACT_APP_MAIN_URL;
      const url = baseUrl + "/3d-viewer/" + opaque;
      console.log("[embed url] =", url);
      return url;
    } catch (error) {
      console.log("❌ error:", error);
    }
  };

  const onRequestEmbed = async (project: Project) => {
    const url = await getEmbedUrl(project);
    if (!url) return;

    const embedText = `
      <div class="clear3d-embed-wrapper">
        <iframe 
        title="${project.name}" 
        frameborder="0" 
        allowfullscreen 
        mozallowfullscreen="true" 
        webkitallowfullscreen="true" 
        allow="fullscreen; autoplay; vr"
        xr-spatial-tracking
        execution-while-out-of-viewport
        execution-while-not-rendered
        web-share src="${url}"
        > </iframe>
      </div>
    `;

    // const embedText = `
    //   <div class="sketchfab-embed-wrapper">
    //     <iframe
    //     title="Shield Harness"
    //     frameborder="0"
    //     allowfullscreen
    //     mozallowfullscreen="true"
    //     webkitallowfullscreen="true"
    //     allow="fullscreen; autoplay; vr"
    //     xr-spatial-tracking
    //     execution-while-out-of-viewport
    //     execution-while-not-rendered
    //     web-share src="https://sketchfab.com/models/590c8802fea743b89dbf03eb47f8593b/embed"
    //     > </iframe>

    //     <p style="font-size: 13px; font-weight: normal; margin: 5px; color: #4A4A4A;">
    //       <a href="https://sketchfab.com/3d-models/shield-harness-590c8802fea743b89dbf03eb47f8593b?utm_medium=embed&utm_campaign=share-popup&utm_content=590c8802fea743b89dbf03eb47f8593b" target="_blank" style="font-weight: bold; color: #1CAAD9;">
    //       Shield Harness </a>
    //       by <a href="https://sketchfab.com/RRD?utm_medium=embed&utm_campaign=share-popup&utm_content=590c8802fea743b89dbf03eb47f8593b" target="_blank" style="font-weight: bold; color: #1CAAD9;">
    //       RRD </a>
    //       on <a href="https://sketchfab.com?utm_medium=embed&utm_campaign=share-popup&utm_content=590c8802fea743b89dbf03eb47f8593b" target="_blank" style="font-weight: bold; color: #1CAAD9;">
    //       Sketchfab</a>
    //     </p>
    //   </div>
    // `;

    //copy url to clipboard
    var input = document.createElement("input");
    input.setAttribute("value", embedText);
    document.body.appendChild(input);
    input.select();
    var result = document.execCommand("copy");
    document.body.removeChild(input);

    MLDialog.showSnackbar("Embed code copied to clipboard, double click to see a preview");
  };

  return (
    <Container
      maxWidth="lg"
      css={{
        // maxWidth: 1024,
        paddingTop: 60,
        "@media only screen and (max-width:500px)": {
          paddingTop: 20,
        },
        paddingBottom: 40,
      }}
    >
      <Grid container direction="row" spacing={2}>
        {loading ? (
          <CircularProgress />
        ) : (
          ["add-btn", ...(projects || [])].map((item: any, index: number) => (
            <Grid item md={4} sm={6} xs={12} key={item?.id || item || index}>
              {item == "add-btn" ? (
                <NewProjectThumbnail onClick={() => history.push("/3d-editor")} />
              ) : (
                <ProjectThumbnail
                  project={item}
                  onRequestEdit={() => history.push("/3d-editor", { projectId: item.id })}
                  onRequestDelete={() => onRequestDelete(item)}
                  onEmbedClick={() => onRequestEmbed(item)}
                  onRequestView={async () => {
                    const url = await getEmbedUrl(item);
                    console.log("push >>>", url);
                    if (url) window.open(url, "_blank");
                  }}
                />
              )}
            </Grid>
          ))
        )}
      </Grid>
    </Container>
  );
}

const ProjectThumbnail = (props: {
  project: Project;
  onRequestDelete: () => void;
  onRequestEdit: () => void;
  onRequestView: () => void;
  onEmbedClick: () => void;
}) => {
  const { project } = props;

  const snapshot = (project.settings as any)?.snapshot;
  const lastEmbedClickMillis = useRef(0);

  // let file = project?.assets?.[0]?.files?.sort((a: any, b: any) => new Date(b.updatedAt) - new Date(a.updatedAt))[0];
  // console.log(16, project, file);

  return (
    <Card>
      <CardHeader title={project.name} />
      <div
        css={{
          height: 150,
          background: "#ddd",
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundImage: "url(" + snapshot + ")",
        }}
      />
      <CardActions>
        <Button variant="text" css={{ color: "#aa0000" }} children="delete" onClick={props.onRequestDelete} />

        <div css={{ flex: 1 }} />

        <Tooltip title="Embed">
          <Button
            variant="outlined"
            css={{ minWidth: 0, boxShadow: "none" }}
            children="</>"
            onClick={() => {
              const curMillis = new Date().getTime();
              if (curMillis - lastEmbedClickMillis.current < 1000) {
                props.onRequestView();
              } else {
                props.onEmbedClick();
              }
              lastEmbedClickMillis.current = curMillis;
            }}
          />
        </Tooltip>

        <Button variant="contained" children="edit" onClick={props.onRequestEdit} />
      </CardActions>
    </Card>
  );
};

const NewProjectThumbnail = (props: { onClick: () => void }) => {
  const color = "#4692d8";
  const dist = 16;
  return (
    <div
      css={{
        cursor: "pointer",
        height: "100%",
        border: "2px solid " + fade(color, 0.3),
        borderStyle: "dashed",
        borderRadius: 8,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        transition: "all 300ms",
        ".add-title": { transform: "translateY(" + dist + "px)", transition: "all 300ms" },
        ".add-muscle": { transform: "translateY(" + -dist + "px)", opacity: 0, transition: "all 300ms" },
        ":hover": {
          transform: "translateY(0px)",
          // opacity: 1
          borderColor: fade(color, 1),
          ".add-title": { transform: "translateY(0px)" },
          ".add-muscle": { transform: "translateY(0px)", opacity: 1 },
        },
      }}
      onClick={props.onClick}
    >
      <Typography
        variant="button"
        children="New scene"
        className="add-title"
        css={{ margin: 24, color: color, fontSize: 24, textAlign: "center" }}
      />
      <div className="add-muscle" children="💪" css={{ fontSize: 32, marginTop: dist }} />
    </div>
  );
};

export default Projects3DPage;
