import { throttle } from "lodash";
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import App from "./App";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import store from "./store";
import { defaultStorekeyLocalStorage } from "./utils";

function getCookie(cname: string) {
  var name = cname + "=";
  var decodedCookie = decodeURIComponent(document.cookie);
  var ca = decodedCookie.split(";");
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) === " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

store.subscribe(
  throttle(() => {
    saveState({ ...store.getState().updates });
  }, 3000)
);

export const saveState = (prevState: any) => {
  //! key local storage to save

  try {
    const { header, ...state } = prevState;
    const serializedState = JSON.stringify(state);

    localStorage.setItem(state?.keySaveState || defaultStorekeyLocalStorage, serializedState);
    const accessToken = getCookie("clear3DAccessToken");
    if (accessToken) {
      const { formValues, stepHistory, currentForm, activeStepName } = state;

      const keysFormValues = Object.keys(formValues) || [];

      if (keysFormValues.length > 0 && currentForm?.length > 0) {
      }
      // console.log("COOKIE", getCookie("clear3DAccessToken"));
    }
  } catch {
    // ignore write errors
  }
};

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
