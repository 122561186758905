/* eslint-disable react-hooks/exhaustive-deps */
/** @jsxImportSource @emotion/react */

import { Ability } from "@casl/ability";
import { Container, Grid, useMediaQuery } from "@material-ui/core";
import CssBaseline from "@material-ui/core/CssBaseline";
import { StylesProvider, ThemeProvider } from "@material-ui/core/styles";
import moment from "moment";
import React, { useEffect } from "react";
import { CookiesProvider, useCookies } from "react-cookie";
import { connect } from "react-redux";
import { BrowserRouter as Router, Redirect, Route, RouteProps, Switch } from "react-router-dom";
import theme from "../src/theme";
import "./assets/css/main.css";
import { MLDialogProvider } from "./components/poppers";
import EmailVerificationSentPage from "./pages/Auth/EmailVerificationSentPage";
import LoginPage from "./pages/Auth/LoginPage";
import LogoutPage from "./pages/Auth/LogoutPage";
import RegisterPage from "./pages/Auth/RegisterPage";
import VerifyEmailPage from "./pages/Auth/VerifyEmailPage";
import WelcomePage from "./pages/Auth/WelcomePage";
import Editor3DPage from "./pages/Editor3DPage";
import Projects3DPage from "./pages/Projects3DPage";
import Viewer3DPage from "./pages/Viewer3DPage";

function App(props: any) {
  moment.locale("it");

  const isMobile = useMediaQuery("@media only screen and (max-width:500px)");

  useEffect(() => {
    console.log(
      `

███╗   ███╗ █████╗ ██████╗ ██╗██╗      ██████╗ ███████╗████████╗
████╗ ████║██╔══██╗██╔══██╗██║██║     ██╔═══██╗██╔════╝╚══██╔══╝
██╔████╔██║███████║██████╔╝██║██║     ██║   ██║█████╗     ██║   
██║╚██╔╝██║██╔══██║██╔══██╗██║██║     ██║   ██║██╔══╝     ██║   
██║ ╚═╝ ██║██║  ██║██████╔╝██║███████╗╚██████╔╝██║        ██║   
╚═╝     ╚═╝╚═╝  ╚═╝╚═════╝ ╚═╝╚══════╝ ╚═════╝ ╚═╝        ╚═╝ 

    `
    );
  }, []);

  return (
    <Router>
      <CookiesProvider>
        <StylesProvider injectFirst>
          {/* {globalStyles} */}
          <ThemeProvider theme={theme}>
            <MLDialogProvider>
              {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
              <CssBaseline />
              <div className="App">
                <Switch>
                  <Route path="/">
                    <CitizenSide />
                  </Route>
                </Switch>

                {/*<div
                  css={{
                    position: "fixed",
                    bottom: 20,
                    right: -94,
                    zIndex: 100000,
                    transition: theme.transitions.create(["transform"]),
                    ":hover": {
                      transform: "translateX(-94px)",
                      cursor: "pointer",
                    },
                  }}
                  onClick={() => {
                     window.open("/changelog.html", "_blank", "noopener noreferrer");
                  }}
                >
                  <img src="/images/mabiloft-logo.png" height={20} alt="awesome-mabiloft" />
                </div>*/}
              </div>
            </MLDialogProvider>
          </ThemeProvider>
        </StylesProvider>
      </CookiesProvider>
    </Router>
  );
}

function CitizenSide() {
  const isMobile = useMediaQuery("@media only screen and (max-width:500px)");

  return (
    <Switch>
      <Route path="/welcome">
        <WelcomePage />
      </Route>

      <Route path="/login">
        <LoginPage />
      </Route>

      <Route path="/register">
        <RegisterPage />
      </Route>

      <ABACRoute allowedIf={{ can: "readOne", model: "Account" }} path="/email-verification-sent">
        <EmailVerificationSentPage />
      </ABACRoute>

      <ABACRoute allowedIf={{ can: "readOne", model: "Account" }} path="/verify-email/:token">
        <VerifyEmailPage />
      </ABACRoute>

      <Route path="/register">
        <RegisterPage />
      </Route>

      <ABACRoute allowedIf={{ can: "update", model: "Account" }} path="/3d-projects">
        <Projects3DPage />
      </ABACRoute>

      <ABACRoute allowedIf={{ can: "update", model: "Account" }} path="/3d-viewer">
        <Viewer3DPage />
      </ABACRoute>

      <ABACRoute allowedIf={{ can: "update", model: "Account" }} path="/3d-editor">
        <Editor3DPage />
      </ABACRoute>

      <Route path="/logout">
        <LogoutPage />
      </Route>

      <Redirect to="/3d-projects" />
    </Switch>
  );
}

type Actions = "create" | "readOne" | "readAll" | "update" | "delete";
interface PrivateRouteProps extends RouteProps {
  allowedIf?: { can: Actions; model: string };
  redirectTo?: string;
}

function ABACRoute({ children, allowedIf, redirectTo, ...rest }: PrivateRouteProps) {
  const [cookies] = useCookies();

  const bonusXToken = cookies["clear3DAccessToken"];

  const rules = JSON.parse(localStorage.getItem("ability-rules") || "[]");

  const ability = new Ability(rules);

  return (
    <Route
      {...rest}
      render={({ location }) =>
        bonusXToken && (allowedIf ? ability.can(allowedIf.can, allowedIf.model) : true) ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: redirectTo || "/welcome",
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    updateQuestionnaire: (questionnaire: any) => dispatch({ type: "UPDATE_QUESTIONNAIRE", payload: { questionnaire } }),
  };
};

export default connect(null, mapDispatchToProps)(App);
