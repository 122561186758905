/* eslint-disable react-hooks/exhaustive-deps */
/** @jsxImportSource @emotion/react */

import axios from "axios";
import { AssetApi, FileApi, ProjectApi } from "bonusx-api-main-manager";
import queryString from "query-string";
import React from "react";
import { useHistory, useRouteMatch } from "react-router";
import { SimpleDropzone } from "simple-dropzone";
import { GLTFExporter } from "three/examples/jsm/exporters/GLTFExporter";
import MLDialog from "../../components/poppers";
import { apiErrorParser, backendMediaUrlParser } from "../../utils";
import EditorWorkbench from "./EditorWorkbench";
import { FBXLoader } from "three/examples/jsm/loaders/FBXLoader";
import "./index.css";
import { Viewer } from "./Viewer";

const apiConfig = {
  basePath: process.env.REACT_APP_MAIN_URL + "/api/v1",
  baseOptions: {
    withCredentials: true,
  },
};

class Editor3DPage extends React.Component {
  state = {
    projectId: undefined,
    projectName: undefined,
    sceneReady: false,
  };

  async loadProject(projectId) {
    try {
      const projectApi = new ProjectApi(apiConfig);
      const fileApi = new FileApi(apiConfig);

      const project = await projectApi.getProjectById(projectId);
      console.log("project files", project.data.assets[0].files);
      const gltfFiles = project.data.assets[0].files.filter((f) => f.url.match(/\.(gltf|glb)$/));
      console.log("filtered files", gltfFiles);
      const file = await fileApi.getFileById(gltfFiles[0].id);
      console.log("to load file", file);

      const model = backendMediaUrlParser(file.data.url);

      console.log("to load model url", model);

      console.log("💚 load from url", project, model);
      this.view(model, "", new Map());
      this.setState({ projectName: project.data.name });
    } catch (ex) {
      MLDialog.showSnackbar("Cannot load file");
    }
  }

  saveScene() {
    if (!this.viewer?.scene) {
      alert("Scene not yet ready");
      return;
    }

    const exporter = new GLTFExporter();

    const binary = true;

    // Parse the input and generate the glTF output
    exporter.parse(
      this.viewer.scene,
      (binary) => this.saveObjectAsBinary(binary),
      //? options: https://threejs.org/docs/#examples/en/exporters/GLTFExporter
      {
        truncateDrawRange: false,
        binary,
        // maxTextureSize: 512 //todo - performance
      }
    );
  }

  async saveObjectAsBinary(binary) {
    const projectApi = new ProjectApi(apiConfig);
    const assetApi = new AssetApi(apiConfig);
    const fileApi = new FileApi(apiConfig);

    //! project -----
    let projectId = this.state.projectId;
    let project;
    if (projectId) {
      const projectResp = await projectApi.getProjectById(projectId);
      project = projectResp.data;
      console.log(10, projectResp);
    } else {
      try {
        const projectName = prompt("Dai un nome al progetto");
        if ((projectName || "").trim() == "") {
          MLDialog.showSnackbar("Il progetto non è stato salvato", { variant: "error" });
          return;
        }

        const projectResp = await projectApi.createProject({
          name: projectName,
          settings: {
            color: "red",
          },
        });
        console.log(11, projectResp);
        project = projectResp.data;
        projectId = project.id;
      } catch (error) {
        console.log("❌1", error);
        MLDialog.showSnackbar(apiErrorParser(error), { variant: "error" });
        return;
      }
    }

    // save project settings (snapshot)
    try {
      const snapshot = this.takeSnapshot();
      this.setState({ snapshot });
      const res = await projectApi.editProject(projectId, {
        settings: { color: "purple", snapshot: snapshot },
      });
      console.log("project update snapshot", res);
    } catch (error) {
      console.log("❌9", error);
      MLDialog.showSnackbar(apiErrorParser(error), { variant: "error" });
      return;
    }

    //! asset -----
    let asset = project?.assets?.[0];
    let assetId = asset?.id;
    if (!assetId) {
      try {
        const assetResp = await assetApi.createAsset({
          name: "Provasset",
          project: projectId,
          settings: {
            color: "blue",
          },
        });
        console.log(22, assetResp);
        asset = assetResp.data;
        assetId = asset.id;
      } catch (error) {
        console.log("❌2", error);
        MLDialog.showSnackbar(apiErrorParser(error), { variant: "error" });
        return;
      }
    }

    //! save file
    const fileName = "filename";
    let file = asset?.files?.sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt))[0];
    let fileId = file?.id;

    //! custom fetch
    const fileToUpload = new File([binary], "model.glb", { type: "model/gltf-binary" });
    // file = await fileApi.creatFile("provafile", assetId, { color: "green" }, gltf);
    var bodyFormData = new FormData();
    bodyFormData.append("name", fileName);
    bodyFormData.append("asset", assetId);
    bodyFormData.append("file", fileToUpload); // this.modelFile is original model from user

    try {
      const fileResp = await axios({
        method: fileId ? "patch" : "post",
        url: apiConfig.basePath + "/file/" + (fileId ?? ""),
        data: bodyFormData,
        headers: { "Content-Type": "multipart/form-data" },
        withCredentials: true,
      });
      console.log("🟨 upload complete", fileResp);
      file = fileResp.data;
    } catch (error) {
      console.log("❌3", error);
      MLDialog.showSnackbar(apiErrorParser(error), { variant: "error" });
      return;
    }

    console.log(
      "🟩 saved",
      "project:",
      projectId,
      "asset:",
      assetId,
      "file:",
      file,
      "url:",
      backendMediaUrlParser(file.url)
    );

    MLDialog.showSnackbar("Il progetto è stato " + (this.state.projectId ? "salvato" : "creato"), {
      variant: "success",
    });

    this.setState({ projectId, projectName: project.name });
  }

  downloadObjectAsGLTF(exportObj, exportName) {
    var dataStr = "data:text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(exportObj));
    var downloadAnchorNode = document.createElement("a");
    downloadAnchorNode.setAttribute("href", dataStr);
    downloadAnchorNode.setAttribute("download", exportName + ".gltf");
    document.body.appendChild(downloadAnchorNode); // required for firefox
    downloadAnchorNode.click();
    downloadAnchorNode.remove();
  }

  render() {
    return (
      <div className="wrap" css={{ height: "100vh" }}>
        {this.state.sceneReady ? (
          <EditorWorkbench
            viewer={this.viewer}
            meshes={this.state.meshes}
            highlightMesh={(mesh) => {
              //todo wip tiz - outlinePass not working
              this.viewer.outlinePass.selectedObjects = mesh;
              // this.viewer.scene.pa
              // console.log(this.viewer.outlinePass.selectedObjects )
            }}
          />
        ) : null}

        {/* SNAPSHOT PREVIEW */}
        {/* <div
          css={{
            position: "fixed",
            zIndex: 10,
            top: 100,
            left: 0,
            width: 300,
            height: 300,
            backgroundColor: "white",
            backgroundSize: "cover",
            backgroundImage: "url(" + this.state.snapshot + ")",
          }}
        /> */}

        {/* DEBUG LOAD BUTTON */}
        {/* <div
          css={{
            position: "fixed",
            zIndex: 10,
            top: 0,
            left: 100,
            margin: 20,
            background: "rgba(0, 0, 0, 0.06)",
            letterSpacing: 2,
            padding: 20,
            ":hover": { background: "rgba(0, 0, 0, 0.12)" },
            cursor: "pointer",
          }}
          children="LOAD"
          onClick={() => this.loadProject("60b69c481758d9000e9cc33d")}
        /> */}

        {this.state.sceneReady ? (
          <div
            css={{
              position: "fixed",
              zIndex: 10,
              top: 0,
              left: 15,
              width: 240,
              height: 60,
              background: "black",
              padding: 8,
              display: "flex",
              flexDirection: "row",
            }}
          >
            <div
              children={this.state.projectName || ""}
              css={{
                fontSize: 16,
                flex: 1,
                textAlign: "left",
                color: "white",
                padding: 8,
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                cursor: "text",
                ":hover": { background: "rgba(255,255,255,0.12)" },
              }}
              onClick={() => {
                //todo wip change name
              }}
            />

            <div
              css={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                textAlign: "center",
                background: "rgba(255,255,255, 0.38)",
                color: "white",
                fontWeight: "bold",
                letterSpacing: 2,
                padding: "8px 16px",
                userSelect: "none",
                lineHeight: "100%",
                ":hover": { background: "rgba(255,255,255,0.5)" },
                cursor: "pointer",
              }}
              children="SAVE"
              onClick={() => this.saveScene()}
            />
          </div>
        ) : null}

        <div
          className="dropzone"
          //ref="dropzone"
          ref={(r) => (this.dropEl = r)}
        >
          <div className="placeholder">
            <p>Drag glTF 2.0 file or folder here</p>
          </div>
          <div className="upload-btn">
            <input
              type="file"
              name="file-input[]"
              id="file-input"
              multiple=""
              //ref="fileInput"
              ref={(r) => (this.inputEl = r)}
            />
            <label htmlFor="file-input">
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="17" viewBox="0 0 20 17">
                <path d="M10 0l-5.2 4.9h3.3v5.1h3.8v-5.1h3.3l-5.2-4.9zm9.3 11.5l-3.2-2.1h-2l3.4 2.6h-3.5c-.1 0-.2.1-.2.1l-.8 2.3h-6l-.8-2.2c-.1-.1-.1-.2-.2-.2h-3.6l3.4-2.6h-2l-3.2 2.1c-.4.3-.7 1-.6 1.5l.6 3.1c.1.5.7.9 1.2.9h16.3c.6 0 1.1-.4 1.3-.9l.6-3.1c.1-.5-.2-1.2-.7-1.5z"></path>
              </svg>
              <span>Upload</span>
            </label>
          </div>
          <div
            className="spinner"
            // ref="spinner"
            ref={(r) => (this.spinnerEl = r)}
          ></div>
          <div ref={(r) => (this.fooEl = r)} />
        </div>
      </div>
    );
  }

  componentDidMount() {
    const location = window.location;

    const hash = location.hash ? queryString.parse(location.hash) : {};
    this.options = {
      kiosk: Boolean(hash.kiosk),
      model: hash.model || "",
      preset: hash.preset || "",
      cameraPosition: hash.cameraPosition ? hash.cameraPosition.split(",").map(Number) : null,
    };

    this.viewer = null;
    this.viewerEl = null;
    console.log("🟥 1", this.spinnerEl); //el.querySelector(".spinner");
    console.log("🟥 2", this.dropEl); //el.querySelector(".dropzone");
    console.log("🟥 3", this.inputEl); //el.querySelector("#file-input");
    console.log("🟥 4", this.fooEl);

    // this.validationCtrl = new ValidationController(this.fooEl); //! validator

    this.createDropzone();
    this.hideSpinner();

    const options = this.options;

    if (options.kiosk) {
      const headerEl = document.querySelector("header");
      headerEl.style.display = "none";
    }

    if (options.model) {
      this.view(options.model, "", new Map());
    }

    if (this.props.projectId) {
      const projectId = this.props.projectId;
      this.setState({ projectId });
      this.loadProject(projectId);
    }
  }

  /**
   * Sets up the drag-and-drop controller.
   */
  createDropzone() {
    const dropCtrl = new SimpleDropzone(this.dropEl, this.inputEl);
    dropCtrl.on("drop", ({ files }) => {
      // ask if user wants to create new project
      if (this.state.sceneReady && !window.confirm("Do you want to replace the current model? Changes will be lost")) {
        return;
      }
      // load files
      this.load(files);
    });
    dropCtrl.on("dropstart", () => this.showSpinner());
    dropCtrl.on("droperror", () => this.hideSpinner());
  }

  /**
   * Sets up the view manager.
   * @return {Viewer}
   */
  createViewer() {
    this.viewerEl = document.createElement("div");
    this.viewerEl.classList.add("viewer");
    this.dropEl.innerHTML = "";
    this.dropEl.appendChild(this.viewerEl);
    this.viewer = new Viewer(this.viewerEl, this.options);
    return this.viewer;
  }

  /**
   * Loads a fileset provided by user action.
   * @param  {Map<string, File>} fileMap
   */
  load(fileMap) {
    let rootFile;
    let rootPath;
    Array.from(fileMap).forEach(([path, file]) => {
      if (file.name.match(/\.(gltf|glb)$/)) {
      // if (file.name.match(/\.(gltf|glb|fbx)$/)) {
        rootFile = file;
        rootPath = path.replace(file.name, "");
      }
    });

    if (!rootFile) {
      this.onError("No .gltf or .glb asset found.");
    }

    this.modelFile = rootFile;

    this.view(rootFile, rootPath, fileMap);
  }

  /**
   * Passes a model to the viewer, given file and resources.
   * @param  {File|string} rootFile
   * @param  {string} rootPath
   * @param  {Map<string, File>} fileMap
   */
  view(rootFile, rootPath, fileMap) {
    this.setState({ sceneReady: false });
    if (this.viewer) this.viewer.clear();

    const viewer = this.viewer || this.createViewer();

    const fileURL = typeof rootFile === "string" ? rootFile : URL.createObjectURL(rootFile);

    const cleanup = () => {
      this.hideSpinner();
      if (typeof rootFile === "object") URL.revokeObjectURL(fileURL);
    };

    viewer
      .load(fileURL, rootPath, fileMap)
      .catch((e) => this.onError(e))
      .then((gltf) => {
        console.log("🟩 loaded", gltf);
        if (!gltf) return;

        this.setState({ sceneReady: true });

        if (!this.options.kiosk) {
          // this.validationCtrl.validate(fileURL, rootPath, fileMap, gltf); //! validator
        }
        cleanup();

        //? load meshes
        const meshes = [];
        this.viewer.content.traverse((node) => {
          // if (node.isMesh) {
          if (node.geometry && node.material) {
            // isMesh || isLineBasigSegment(wireframe)
            meshes.push(node);
          }
        });
        this.setState({ meshes });
      });
  }

  /**
   * @param  {Error} error
   */
  onError(error) {
    let message = (error || {}).message || error.toString();
    if (message.match(/ProgressEvent/)) {
      message = "Unable to retrieve this file. Check JS console and browser network tab.";
    } else if (message.match(/Unexpected token/)) {
      message = `Unable to parse file content. Verify that this file is valid. Error: "${message}"`;
    } else if (error && error.target && error.target instanceof Image) {
      message = "Missing texture: " + error.target.src.split("/").pop();
    }
    window.alert(message);
    console.error(error);
  }

  showSpinner() {
    this.spinnerEl.style.display = "";
  }

  hideSpinner() {
    this.spinnerEl.style.display = "none";
  }

  /**
   * Takes a snapshot of the viewer
   * @returns base64 image
   * @returns null if something went wrong
   */
  takeSnapshot() {
    try {
      const strMime = "image/jpeg";
      this.viewer.renderer.render(this.viewer.scene, this.viewer.activeCamera);
      const canvas = this.viewer.renderer.domElement;

      //? full resolution
      // let imgData = canvas.toDataURL(strMime);
      // console.log("🟧 snapshot", imgData);
      // if(true) return imgData;

      //? resized
      const aspectRatio = (canvas.width || 1) / (canvas.height || 1);
      let width = 300;
      let height = width / aspectRatio;

      const resizedCanvas = document.createElement("canvas");
      const resizedContext = resizedCanvas.getContext("2d");

      resizedCanvas.width = "" + width;
      resizedCanvas.height = "" + height;

      resizedContext.drawImage(canvas, 0, 0, width, height);
      const myResizedData = resizedCanvas.toDataURL(strMime);
      // console.log("🟧 resized", myResizedData);

      return myResizedData;
    } catch (err) {
      console.log("❌ snaphost", err);
      return null;
    }
  }
}

const Editor3DPageWithProps = () => {
  const { path } = useRouteMatch();
  const history = useHistory();

  const projectId = history?.location?.state?.projectId;

  return <Editor3DPage projectId={projectId} />;
};

// export default Editor3DPage;
export default Editor3DPageWithProps;
