/* eslint-disable react-hooks/exhaustive-deps */
/** @jsxImportSource @emotion/react */

import { Typography } from "@material-ui/core";
import { ChangeEvent, useCallback, useRef, useState } from "react";
import { useDropzone } from "react-dropzone";

// import { GUI } from "dat.gui";
// import { MeshoptDecoder } from "three/examples/jsm/libs/meshopt_decoder.module.js";
// import { environments } from "../assets/environment/index.js";
// import { createBackground } from "../lib/three-vignette.js";

const DropFiles = (props: { children: any; onDropFiles: (files: File[]) => void }) => {
  const onDrop = useCallback((acceptedFiles) => {
    // Do something with the files
    console.log('files:', acceptedFiles);
    props?.onDropFiles(acceptedFiles);
  }, []);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  return (
    <div {...getRootProps()}>
      <input {...getInputProps()} />
      {/* {isDragActive ? <p>Drop the files here ...</p> : <p>Drag 'n' drop some files here, or click to select files</p>} */}
      {props.children}
    </div>
  );
};

/*
const DropFiles = (props: {
  children: any;
  onFiles: (files: string[]) => void;
  onDropFiles: (files: any[]) => void;
}) => {
  function traverseFileTree(item: any, path?: string) {
    path = path || "";
    if (item.isFile) {
      // Get file
      item.file(function (file: File) {
        const filePath = path + file.name;
        console.log("File:", filePath);
        if (filePath.endsWith(".gltf")) {
          props.onFiles?.([filePath]);
        }
      });
    } else if (item.isDirectory) {
      // Get folder contents
      var dirReader = item.createReader();
      dirReader.readEntries(function (entries: any) {
        for (var i = 0; i < entries.length; i++) {
          traverseFileTree(entries[i], path + item.name + "/");
        }
      });
    }
  }

  const onDrop = (event: any) => {
    event.preventDefault();

    console.log(123, event, event.nativeEvent.files)
    // props.onDropFiles(event.files);

    const files: string[] = [];

    var items = event.dataTransfer.items;
    for (var i = 0; i < items.length; i++) {
      // webkitGetAsEntry is where the magic happens
      var item = items[i].webkitGetAsEntry();
      if (item) {
        traverseFileTree(item);
      }
    }
  };

  return (
    <div
      onDragOver={(e) => {
        e.preventDefault();
        //class-add-hover
      }}
      onDragExit={(e) => {
        e.preventDefault();
        //class-remove-hover
      }}
      onDragEnd={(e) => {
        e.preventDefault();
        //class-remove-hover
      }}
      onDrop={(e) => {
        e.preventDefault();
        //class-remove-hover
        onDrop(e);
      }}
      children={props.children}
    />
  );
};
*/

export default DropFiles;
