/** @jsxImportSource @emotion/react */

import { Grid, Typography, useTheme } from "@material-ui/core";
import { AuthenticationApi } from "bonusx-api-main-manager";

import { useEffect } from "react";
import { useParams } from "react-router";
import MLDialog from "../../components/poppers";
import { apiErrorParser } from "../../utils";
import { useApi } from "../../utils/api";
import "./verify-email.css";

function VerifyEmailPage() {
  const authApi = useApi(AuthenticationApi);
  const theme = useTheme();

  const { token } = useParams<{ token: string }>();

  useEffect(() => {
    if (token?.length > 0) {
      //! calls api to verify
      //? then after 5 seconds redirects to profile verification
      authApi
        .verifyEmail({ token })
        .then((response) => {
          localStorage.setItem("ability-rules", JSON.stringify(response?.data?.abilityRules || []));

          //! after 5 seconds redirect
          setTimeout(() => {
            window.location.href = "/dashboard?fill-family-data=1";
          }, 5000);
        })
        .catch((error) => {
          console.log(apiErrorParser(error));
          MLDialog.showSnackbar("Qualcosa è andato storto", { variant: "error" });
        });
    }
  }, [token]);

  return (
    <Grid
      container
      alignItems="center"
      direction="column"
      css={{
        paddingLeft: 10,
        paddingRight: 10,
        paddingTop: 100,
        "@media only screen and (max-width:500px)": {
          paddingTop: 20,
        },
      }}
    >
      <Typography
        variant="h3"
        children="La tua e-mail è stata verificata"
        css={{ marginTop: 20, textAlign: "center" }}
      />
      <Typography
        children={[
          "Non te ne andare.",
          <br key="br1" />,
          "Ti stiamo reindirizzando sulla pagina delle prestazioni richiedibili.",
          <br key="br2" />,
          "Attendi qualche secondo.",
        ]}
        css={{ marginTop: 20, maxWidth: 510, textAlign: "center" }}
      />

      <Grid
        container
        justify="center"
        css={{
          marginTop: 30,
          "*": {
            animationName: "verify-email-dots",
            animationDuration: "1s",
            animationFillMode: "both",
            animationIterationCount: "infinite",
            animationTimingFunction: "linear",
          },
        }}
      >
        <div
          css={{
            animationDelay: "0ms",
            width: 14,
            height: 14,
            borderRadius: 10,
            backgroundColor: theme.palette.secondary.main,
            marginRight: 5,
          }}
        />
        <div
          css={{
            animationDelay: "250ms",
            width: 14,
            height: 14,
            borderRadius: 10,
            backgroundColor: theme.palette.primary.main,
          }}
        />
        <div
          css={{
            animationDelay: "500ms",
            width: 14,
            height: 14,
            borderRadius: 10,
            backgroundColor: "#FB3D75",
            marginLeft: 5,
          }}
        />
      </Grid>
    </Grid>
  );
}

export default VerifyEmailPage;
