import _ from "lodash";
import citizenship from "../assets/citizenship.json";
import family from "../assets/family.json";
import patrimonio from "../assets/patrimonio.json";
import redditi from "../assets/redditi.json";
import welcome from "../assets/welcome.json";
import { getNextForm } from "../functions/formutil";
import { defaultStorekeyLocalStorage, getCurrentStateFromLocalStorage } from "../utils";

const forms = [welcome, citizenship, family, redditi, patrimonio];

const initialState = {
  formValues: {},
  stepHistory: [],
  changeSection: { section: "welcome", direction: null },
  currentForm: "welcome",
  activeStep: 0,
  activeStepName: forms[0].sections[0].forms[0].stepname,
  validationField: {},
  header: {
    drawerOpen: false,
    visible: true,
    visibleActions: [],
    showDataSaved: true,
  },
  cart: [],
  forms,
  redirectOnEndSection: "",
  redirectOnFinishSection: "",
  keySaveState: defaultStorekeyLocalStorage
  // globalValues: []
};

export default function updates(
  state = getCurrentStateFromLocalStorage(initialState),
  // state = initialState,
  action
) {
  switch (action.type) {

    case "CHANGE_SECTION": {
      const form = getNextForm(
        state.forms,
        action.payload.section || state.currentForm,
        action.payload.direction
      );

      return {
        ...state,
        currentForm: form.name,
        activeStep: 0,
        stepHistory: [],
        activeStepName: action.payload.activeStepName || form.sections[0].forms[0].stepname,
      };
    }
    case "CHANGE_STEP": {
      return {
        ...state,
        activeStep: action.payload,
      };
    }
    case "CHANGE_STEP_NAME": {
      return {
        ...state,
        activeStepName: action.payload,
      };
    }
    case "UPDATE_FIELD": {
      const prevFormValues = state.formValues;
      const forms = state.forms;

      const fieldName = action.payload.name;
      const fieldValue = action.payload.value;

      let newFormValues = prevFormValues;

      if (prevFormValues[fieldName] !== fieldValue) {
        const prevFormValuesKeys = Object.keys(prevFormValues || {});

        const allFormFields = [];
        const notDependentFields = [];

        forms.forEach((form) => {
          const sections = form.sections;
          const steps = sections[0].forms;

          steps.forEach((step) => {
            step.fields.forEach((field) => {
              allFormFields.push(field.name);
              if (
                !field.dependencies ||
                !field.dependencies.includes(fieldName)
              ) {
                notDependentFields.push(field.name);
              }
            });
          });
        });

        const diffPrevFields = _.difference(prevFormValuesKeys, allFormFields);
        const mergeBetweenNotToExcludeFieldsAndNotDepdendent = _.union(
          diffPrevFields,
          notDependentFields
        );

        newFormValues = {};

        mergeBetweenNotToExcludeFieldsAndNotDepdendent.forEach((x) => {
          if (prevFormValues[x] !== undefined) {
            newFormValues[x] = prevFormValues[x];
          }
        });

        //! repopulates with default
        forms.forEach((form) => {
          const sections = form.sections;
          const steps = sections[0].forms;

          steps.forEach((step) => {
            step.fields.forEach((field) => {
              if (
                (newFormValues[field.name] === undefined ||
                  newFormValues[field.name] === null) &&
                field.default !== undefined
              ) {
                newFormValues[field.name] = field.default;
              }
            });
          });
        });

      }

      return {
        ...state,
        formValues: { ...newFormValues, [fieldName]: fieldValue },
      };
    }
    case "RESTORE_SECTION": {
      return {
        ...state,
        changeSection: {
          section: state.changeSection.section,
          direction: null,
        },
      };
    }

    case "VALIDATION_FIELD_ERROR": {
      return {
        ...state,
        validationField: action.payload,
      };
    }

    case "CHANGE_CURRENT_FORM": {
      return {
        ...state,
        currentForm: action.payload.form,
      };
    }

    case "UPDATE_QUESTIONNAIRE": {
      return { ...state, ...action.payload.questionnaire };
    }

    case "HEADER": {
      return { ...state, header: { ...state.header, ...action.payload } }
    }



    case "UPDATE_ENTIRE_FORMS": {
      return { ...state, forms: action.payload }
    }



    //FUNCTIONS ------------------------ CART --------------------------------



    case "ADD_TO_CART": {
      //! create a key ? maybe uuid

      const newItem = action.payload;

      return { ...state, cart: [...(state?.cart || []), newItem] }
    }

    case "CHANGE_QTY_ITEM_CART": {
      //! create a key ? maybe uuid

      let tmpCart = [...state.cart];

      const serviceId = action.payload.serviceId;
      const count = action.payload.count;
      const findItemIndex = tmpCart.findIndex(x => x.service.id === serviceId);
      if (findItemIndex === -1) return { ...state }

      tmpCart[findItemIndex].count = count;

      return { ...state, cart: tmpCart }
    }


    case "REMOVE_FROM_CART": {


      let tmpCart = [...state.cart];

      const serviceId = action.payload;
      const findItemIndex = tmpCart.findIndex(x => x.service.id === serviceId);
      if (findItemIndex === -1) return { ...state }

      tmpCart.splice(findItemIndex, 1)


      return { ...state, cart: tmpCart }
    }

    default:
      return state;
  }
}
