"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * Clear3D
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProjectApi = exports.ProjectApiFactory = exports.ProjectApiFp = exports.ProjectApiAxiosParamCreator = exports.FileApi = exports.FileApiFactory = exports.FileApiFp = exports.FileApiAxiosParamCreator = exports.AuthenticationApi = exports.AuthenticationApiFactory = exports.AuthenticationApiFp = exports.AuthenticationApiAxiosParamCreator = exports.AssetApi = exports.AssetApiFactory = exports.AssetApiFp = exports.AssetApiAxiosParamCreator = exports.AccountApi = exports.AccountApiFactory = exports.AccountApiFp = exports.AccountApiAxiosParamCreator = void 0;
const axios_1 = require("axios");
// Some imports not used depending on template conditions
// @ts-ignore
const common_1 = require("./common");
// @ts-ignore
const base_1 = require("./base");
/**
 * AccountApi - axios parameter creator
 * @export
 */
exports.AccountApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMe: (options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/account/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            yield common_1.setBearerAuthToObject(localVarHeaderParameter, configuration);
            // authentication cookieAuth required
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
    };
};
/**
 * AccountApi - functional programming interface
 * @export
 */
exports.AccountApiFp = function (configuration) {
    const localVarAxiosParamCreator = exports.AccountApiAxiosParamCreator(configuration);
    return {
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMe(options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getMe(options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
    };
};
/**
 * AccountApi - factory interface
 * @export
 */
exports.AccountApiFactory = function (configuration, basePath, axios) {
    const localVarFp = exports.AccountApiFp(configuration);
    return {
        /**
         *
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMe(options) {
            return localVarFp.getMe(options).then((request) => request(axios, basePath));
        },
    };
};
/**
 * AccountApi - object-oriented interface
 * @export
 * @class AccountApi
 * @extends {BaseAPI}
 */
class AccountApi extends base_1.BaseAPI {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    getMe(options) {
        return exports.AccountApiFp(this.configuration).getMe(options).then((request) => request(this.axios, this.basePath));
    }
}
exports.AccountApi = AccountApi;
/**
 * AssetApi - axios parameter creator
 * @export
 */
exports.AssetApiAxiosParamCreator = function (configuration) {
    return {
        /**
         * Create an asset
         * @param {InputCreateAsset} [inputCreateAsset] Create an asset
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAsset: (inputCreateAsset, options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/asset/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            yield common_1.setBearerAuthToObject(localVarHeaderParameter, configuration);
            // authentication cookieAuth required
            localVarHeaderParameter['Content-Type'] = 'application/json';
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = common_1.serializeDataIfNeeded(inputCreateAsset, localVarRequestOptions, configuration);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         * delete an asset
         * @param {number | string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAsset: (id, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'id' is not null or undefined
            common_1.assertParamExists('deleteAsset', 'id', id);
            const localVarPath = `/asset/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'DELETE' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            yield common_1.setBearerAuthToObject(localVarHeaderParameter, configuration);
            // authentication cookieAuth required
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         * Edit an asset
         * @param {number | string} id
         * @param {InputEditAsset} [inputEditAsset] Edit an asset
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editAsset: (id, inputEditAsset, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'id' is not null or undefined
            common_1.assertParamExists('editAsset', 'id', id);
            const localVarPath = `/asset/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'PATCH' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            yield common_1.setBearerAuthToObject(localVarHeaderParameter, configuration);
            // authentication cookieAuth required
            localVarHeaderParameter['Content-Type'] = 'application/json';
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = common_1.serializeDataIfNeeded(inputEditAsset, localVarRequestOptions, configuration);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         * Gets asset by id
         * @param {number | string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAssetById: (id, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'id' is not null or undefined
            common_1.assertParamExists('getAssetById', 'id', id);
            const localVarPath = `/asset/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            yield common_1.setBearerAuthToObject(localVarHeaderParameter, configuration);
            // authentication cookieAuth required
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         * Gets all assets in pages   Default page size **10**  Can be filtered by **projectId**
         * @param {number} [page]
         * @param {number} [size]
         * @param {string} [projectId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAssets: (page, size, projectId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/asset/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            yield common_1.setBearerAuthToObject(localVarHeaderParameter, configuration);
            // authentication cookieAuth required
            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }
            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }
            if (projectId !== undefined) {
                localVarQueryParameter['projectId'] = projectId;
            }
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
    };
};
/**
 * AssetApi - functional programming interface
 * @export
 */
exports.AssetApiFp = function (configuration) {
    const localVarAxiosParamCreator = exports.AssetApiAxiosParamCreator(configuration);
    return {
        /**
         * Create an asset
         * @param {InputCreateAsset} [inputCreateAsset] Create an asset
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAsset(inputCreateAsset, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.createAsset(inputCreateAsset, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         * delete an asset
         * @param {number | string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAsset(id, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.deleteAsset(id, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         * Edit an asset
         * @param {number | string} id
         * @param {InputEditAsset} [inputEditAsset] Edit an asset
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editAsset(id, inputEditAsset, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.editAsset(id, inputEditAsset, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         * Gets asset by id
         * @param {number | string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAssetById(id, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getAssetById(id, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         * Gets all assets in pages   Default page size **10**  Can be filtered by **projectId**
         * @param {number} [page]
         * @param {number} [size]
         * @param {string} [projectId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAssets(page, size, projectId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getAssets(page, size, projectId, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
    };
};
/**
 * AssetApi - factory interface
 * @export
 */
exports.AssetApiFactory = function (configuration, basePath, axios) {
    const localVarFp = exports.AssetApiFp(configuration);
    return {
        /**
         * Create an asset
         * @param {InputCreateAsset} [inputCreateAsset] Create an asset
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAsset(inputCreateAsset, options) {
            return localVarFp.createAsset(inputCreateAsset, options).then((request) => request(axios, basePath));
        },
        /**
         * delete an asset
         * @param {number | string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteAsset(id, options) {
            return localVarFp.deleteAsset(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Edit an asset
         * @param {number | string} id
         * @param {InputEditAsset} [inputEditAsset] Edit an asset
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editAsset(id, inputEditAsset, options) {
            return localVarFp.editAsset(id, inputEditAsset, options).then((request) => request(axios, basePath));
        },
        /**
         * Gets asset by id
         * @param {number | string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAssetById(id, options) {
            return localVarFp.getAssetById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Gets all assets in pages   Default page size **10**  Can be filtered by **projectId**
         * @param {number} [page]
         * @param {number} [size]
         * @param {string} [projectId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAssets(page, size, projectId, options) {
            return localVarFp.getAssets(page, size, projectId, options).then((request) => request(axios, basePath));
        },
    };
};
/**
 * AssetApi - object-oriented interface
 * @export
 * @class AssetApi
 * @extends {BaseAPI}
 */
class AssetApi extends base_1.BaseAPI {
    /**
     * Create an asset
     * @param {InputCreateAsset} [inputCreateAsset] Create an asset
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssetApi
     */
    createAsset(inputCreateAsset, options) {
        return exports.AssetApiFp(this.configuration).createAsset(inputCreateAsset, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * delete an asset
     * @param {number | string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssetApi
     */
    deleteAsset(id, options) {
        return exports.AssetApiFp(this.configuration).deleteAsset(id, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Edit an asset
     * @param {number | string} id
     * @param {InputEditAsset} [inputEditAsset] Edit an asset
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssetApi
     */
    editAsset(id, inputEditAsset, options) {
        return exports.AssetApiFp(this.configuration).editAsset(id, inputEditAsset, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Gets asset by id
     * @param {number | string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssetApi
     */
    getAssetById(id, options) {
        return exports.AssetApiFp(this.configuration).getAssetById(id, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Gets all assets in pages   Default page size **10**  Can be filtered by **projectId**
     * @param {number} [page]
     * @param {number} [size]
     * @param {string} [projectId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AssetApi
     */
    getAssets(page, size, projectId, options) {
        return exports.AssetApiFp(this.configuration).getAssets(page, size, projectId, options).then((request) => request(this.axios, this.basePath));
    }
}
exports.AssetApi = AssetApi;
/**
 * AuthenticationApi - axios parameter creator
 * @export
 */
exports.AuthenticationApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *
         * @summary User can login through email and password
         * @param {InputAuthenticationLogin} [inputAuthenticationLogin]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        login: (inputAuthenticationLogin, options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/authentication/login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            yield common_1.setBearerAuthToObject(localVarHeaderParameter, configuration);
            // authentication cookieAuth required
            localVarHeaderParameter['Content-Type'] = 'application/json';
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = common_1.serializeDataIfNeeded(inputAuthenticationLogin, localVarRequestOptions, configuration);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Logout from bonusx
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logout: (options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/authentication/logout`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            yield common_1.setBearerAuthToObject(localVarHeaderParameter, configuration);
            // authentication cookieAuth required
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Registration with email and password
         * @param {InputAuthenticationRegister} [inputAuthenticationRegister]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        register: (inputAuthenticationRegister, options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/authentication/register`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            yield common_1.setBearerAuthToObject(localVarHeaderParameter, configuration);
            // authentication cookieAuth required
            localVarHeaderParameter['Content-Type'] = 'application/json';
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = common_1.serializeDataIfNeeded(inputAuthenticationRegister, localVarRequestOptions, configuration);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @summary Verify current account by passing a token provided through a verified email
         * @param {InputVerifyEmail} [inputVerifyEmail]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verifyEmail: (inputVerifyEmail, options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/authentication/verify-email`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            yield common_1.setBearerAuthToObject(localVarHeaderParameter, configuration);
            // authentication cookieAuth required
            localVarHeaderParameter['Content-Type'] = 'application/json';
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = common_1.serializeDataIfNeeded(inputVerifyEmail, localVarRequestOptions, configuration);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
    };
};
/**
 * AuthenticationApi - functional programming interface
 * @export
 */
exports.AuthenticationApiFp = function (configuration) {
    const localVarAxiosParamCreator = exports.AuthenticationApiAxiosParamCreator(configuration);
    return {
        /**
         *
         * @summary User can login through email and password
         * @param {InputAuthenticationLogin} [inputAuthenticationLogin]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        login(inputAuthenticationLogin, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.login(inputAuthenticationLogin, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Logout from bonusx
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logout(options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.logout(options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Registration with email and password
         * @param {InputAuthenticationRegister} [inputAuthenticationRegister]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        register(inputAuthenticationRegister, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.register(inputAuthenticationRegister, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @summary Verify current account by passing a token provided through a verified email
         * @param {InputVerifyEmail} [inputVerifyEmail]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verifyEmail(inputVerifyEmail, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.verifyEmail(inputVerifyEmail, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
    };
};
/**
 * AuthenticationApi - factory interface
 * @export
 */
exports.AuthenticationApiFactory = function (configuration, basePath, axios) {
    const localVarFp = exports.AuthenticationApiFp(configuration);
    return {
        /**
         *
         * @summary User can login through email and password
         * @param {InputAuthenticationLogin} [inputAuthenticationLogin]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        login(inputAuthenticationLogin, options) {
            return localVarFp.login(inputAuthenticationLogin, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Logout from bonusx
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        logout(options) {
            return localVarFp.logout(options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Registration with email and password
         * @param {InputAuthenticationRegister} [inputAuthenticationRegister]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        register(inputAuthenticationRegister, options) {
            return localVarFp.register(inputAuthenticationRegister, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @summary Verify current account by passing a token provided through a verified email
         * @param {InputVerifyEmail} [inputVerifyEmail]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        verifyEmail(inputVerifyEmail, options) {
            return localVarFp.verifyEmail(inputVerifyEmail, options).then((request) => request(axios, basePath));
        },
    };
};
/**
 * AuthenticationApi - object-oriented interface
 * @export
 * @class AuthenticationApi
 * @extends {BaseAPI}
 */
class AuthenticationApi extends base_1.BaseAPI {
    /**
     *
     * @summary User can login through email and password
     * @param {InputAuthenticationLogin} [inputAuthenticationLogin]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    login(inputAuthenticationLogin, options) {
        return exports.AuthenticationApiFp(this.configuration).login(inputAuthenticationLogin, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Logout from bonusx
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    logout(options) {
        return exports.AuthenticationApiFp(this.configuration).logout(options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Registration with email and password
     * @param {InputAuthenticationRegister} [inputAuthenticationRegister]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    register(inputAuthenticationRegister, options) {
        return exports.AuthenticationApiFp(this.configuration).register(inputAuthenticationRegister, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @summary Verify current account by passing a token provided through a verified email
     * @param {InputVerifyEmail} [inputVerifyEmail]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthenticationApi
     */
    verifyEmail(inputVerifyEmail, options) {
        return exports.AuthenticationApiFp(this.configuration).verifyEmail(inputVerifyEmail, options).then((request) => request(this.axios, this.basePath));
    }
}
exports.AuthenticationApi = AuthenticationApi;
/**
 * FileApi - axios parameter creator
 * @export
 */
exports.FileApiAxiosParamCreator = function (configuration) {
    return {
        /**
         * Create a file
         * @param {string} [name]
         * @param {string} [asset]
         * @param {object} [settings]
         * @param {any} [file]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        creatFile: (name, asset, settings, file, options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/file/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();
            // authentication bearerAuth required
            // http bearer authentication required
            yield common_1.setBearerAuthToObject(localVarHeaderParameter, configuration);
            // authentication cookieAuth required
            if (name !== undefined) {
                localVarFormParams.append('name', name);
            }
            if (asset !== undefined) {
                localVarFormParams.append('asset', asset);
            }
            if (settings !== undefined) {
                localVarFormParams.append('settings', new Blob([JSON.stringify(settings)], { type: "application/json", }));
            }
            if (file !== undefined) {
                localVarFormParams.append('file', file);
            }
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = localVarFormParams;
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         * delete a file
         * @param {number | string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteFile: (id, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'id' is not null or undefined
            common_1.assertParamExists('deleteFile', 'id', id);
            const localVarPath = `/file/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'DELETE' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            yield common_1.setBearerAuthToObject(localVarHeaderParameter, configuration);
            // authentication cookieAuth required
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         * Edit a file
         * @param {number | string} id
         * @param {string} [name]
         * @param {object} [settings]
         * @param {any} [file]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editFile: (id, name, settings, file, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'id' is not null or undefined
            common_1.assertParamExists('editFile', 'id', id);
            const localVarPath = `/file/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'PATCH' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();
            // authentication bearerAuth required
            // http bearer authentication required
            yield common_1.setBearerAuthToObject(localVarHeaderParameter, configuration);
            // authentication cookieAuth required
            if (name !== undefined) {
                localVarFormParams.append('name', name);
            }
            if (settings !== undefined) {
                localVarFormParams.append('settings', new Blob([JSON.stringify(settings)], { type: "application/json", }));
            }
            if (file !== undefined) {
                localVarFormParams.append('file', file);
            }
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = localVarFormParams;
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         * Gets file by id
         * @param {number | string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFileById: (id, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'id' is not null or undefined
            common_1.assertParamExists('getFileById', 'id', id);
            const localVarPath = `/file/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            yield common_1.setBearerAuthToObject(localVarHeaderParameter, configuration);
            // authentication cookieAuth required
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         * Gets all files in pages   Default page size **10**  Can be filtered by **projectId**
         * @param {number} [page]
         * @param {number} [size]
         * @param {string} [assetId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFiles: (page, size, assetId, options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/file/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            yield common_1.setBearerAuthToObject(localVarHeaderParameter, configuration);
            // authentication cookieAuth required
            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }
            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }
            if (assetId !== undefined) {
                localVarQueryParameter['assetId'] = assetId;
            }
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
    };
};
/**
 * FileApi - functional programming interface
 * @export
 */
exports.FileApiFp = function (configuration) {
    const localVarAxiosParamCreator = exports.FileApiAxiosParamCreator(configuration);
    return {
        /**
         * Create a file
         * @param {string} [name]
         * @param {string} [asset]
         * @param {object} [settings]
         * @param {any} [file]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        creatFile(name, asset, settings, file, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.creatFile(name, asset, settings, file, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         * delete a file
         * @param {number | string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteFile(id, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.deleteFile(id, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         * Edit a file
         * @param {number | string} id
         * @param {string} [name]
         * @param {object} [settings]
         * @param {any} [file]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editFile(id, name, settings, file, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.editFile(id, name, settings, file, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         * Gets file by id
         * @param {number | string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFileById(id, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getFileById(id, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         * Gets all files in pages   Default page size **10**  Can be filtered by **projectId**
         * @param {number} [page]
         * @param {number} [size]
         * @param {string} [assetId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFiles(page, size, assetId, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getFiles(page, size, assetId, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
    };
};
/**
 * FileApi - factory interface
 * @export
 */
exports.FileApiFactory = function (configuration, basePath, axios) {
    const localVarFp = exports.FileApiFp(configuration);
    return {
        /**
         * Create a file
         * @param {string} [name]
         * @param {string} [asset]
         * @param {object} [settings]
         * @param {any} [file]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        creatFile(name, asset, settings, file, options) {
            return localVarFp.creatFile(name, asset, settings, file, options).then((request) => request(axios, basePath));
        },
        /**
         * delete a file
         * @param {number | string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteFile(id, options) {
            return localVarFp.deleteFile(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Edit a file
         * @param {number | string} id
         * @param {string} [name]
         * @param {object} [settings]
         * @param {any} [file]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editFile(id, name, settings, file, options) {
            return localVarFp.editFile(id, name, settings, file, options).then((request) => request(axios, basePath));
        },
        /**
         * Gets file by id
         * @param {number | string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFileById(id, options) {
            return localVarFp.getFileById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Gets all files in pages   Default page size **10**  Can be filtered by **projectId**
         * @param {number} [page]
         * @param {number} [size]
         * @param {string} [assetId]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFiles(page, size, assetId, options) {
            return localVarFp.getFiles(page, size, assetId, options).then((request) => request(axios, basePath));
        },
    };
};
/**
 * FileApi - object-oriented interface
 * @export
 * @class FileApi
 * @extends {BaseAPI}
 */
class FileApi extends base_1.BaseAPI {
    /**
     * Create a file
     * @param {string} [name]
     * @param {string} [asset]
     * @param {object} [settings]
     * @param {any} [file]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileApi
     */
    creatFile(name, asset, settings, file, options) {
        return exports.FileApiFp(this.configuration).creatFile(name, asset, settings, file, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * delete a file
     * @param {number | string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileApi
     */
    deleteFile(id, options) {
        return exports.FileApiFp(this.configuration).deleteFile(id, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Edit a file
     * @param {number | string} id
     * @param {string} [name]
     * @param {object} [settings]
     * @param {any} [file]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileApi
     */
    editFile(id, name, settings, file, options) {
        return exports.FileApiFp(this.configuration).editFile(id, name, settings, file, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Gets file by id
     * @param {number | string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileApi
     */
    getFileById(id, options) {
        return exports.FileApiFp(this.configuration).getFileById(id, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Gets all files in pages   Default page size **10**  Can be filtered by **projectId**
     * @param {number} [page]
     * @param {number} [size]
     * @param {string} [assetId]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileApi
     */
    getFiles(page, size, assetId, options) {
        return exports.FileApiFp(this.configuration).getFiles(page, size, assetId, options).then((request) => request(this.axios, this.basePath));
    }
}
exports.FileApi = FileApi;
/**
 * ProjectApi - axios parameter creator
 * @export
 */
exports.ProjectApiAxiosParamCreator = function (configuration) {
    return {
        /**
         * Create a project
         * @param {InputCreateProject} [inputCreateProject] Create a project
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createProject: (inputCreateProject, options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/project/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'POST' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            yield common_1.setBearerAuthToObject(localVarHeaderParameter, configuration);
            // authentication cookieAuth required
            localVarHeaderParameter['Content-Type'] = 'application/json';
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = common_1.serializeDataIfNeeded(inputCreateProject, localVarRequestOptions, configuration);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         * delete a project
         * @param {number | string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProject: (id, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'id' is not null or undefined
            common_1.assertParamExists('deleteProject', 'id', id);
            const localVarPath = `/project/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'DELETE' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            yield common_1.setBearerAuthToObject(localVarHeaderParameter, configuration);
            // authentication cookieAuth required
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         * Edit a project
         * @param {number | string} id
         * @param {InputCreateProject} [inputCreateProject] Edit a project
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editProject: (id, inputCreateProject, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'id' is not null or undefined
            common_1.assertParamExists('editProject', 'id', id);
            const localVarPath = `/project/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'PATCH' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            yield common_1.setBearerAuthToObject(localVarHeaderParameter, configuration);
            // authentication cookieAuth required
            localVarHeaderParameter['Content-Type'] = 'application/json';
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            localVarRequestOptions.data = common_1.serializeDataIfNeeded(inputCreateProject, localVarRequestOptions, configuration);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         * Gets project by id
         * @param {number | string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectById: (id, options = {}) => __awaiter(this, void 0, void 0, function* () {
            // verify required parameter 'id' is not null or undefined
            common_1.assertParamExists('getProjectById', 'id', id);
            const localVarPath = `/project/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            yield common_1.setBearerAuthToObject(localVarHeaderParameter, configuration);
            // authentication cookieAuth required
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
        /**
         *
         * @param {number} [page]
         * @param {number} [size]
         * @param {string} [name]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjects: (page, size, name, options = {}) => __awaiter(this, void 0, void 0, function* () {
            const localVarPath = `/project/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, common_1.DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: 'GET' }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            yield common_1.setBearerAuthToObject(localVarHeaderParameter, configuration);
            // authentication cookieAuth required
            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }
            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }
            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }
            common_1.setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = Object.assign(Object.assign(Object.assign({}, localVarHeaderParameter), headersFromBaseOptions), options.headers);
            return {
                url: common_1.toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        }),
    };
};
/**
 * ProjectApi - functional programming interface
 * @export
 */
exports.ProjectApiFp = function (configuration) {
    const localVarAxiosParamCreator = exports.ProjectApiAxiosParamCreator(configuration);
    return {
        /**
         * Create a project
         * @param {InputCreateProject} [inputCreateProject] Create a project
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createProject(inputCreateProject, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.createProject(inputCreateProject, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         * delete a project
         * @param {number | string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProject(id, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.deleteProject(id, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         * Edit a project
         * @param {number | string} id
         * @param {InputCreateProject} [inputCreateProject] Edit a project
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editProject(id, inputCreateProject, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.editProject(id, inputCreateProject, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         * Gets project by id
         * @param {number | string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectById(id, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getProjectById(id, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
        /**
         *
         * @param {number} [page]
         * @param {number} [size]
         * @param {string} [name]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjects(page, size, name, options) {
            return __awaiter(this, void 0, void 0, function* () {
                const localVarAxiosArgs = yield localVarAxiosParamCreator.getProjects(page, size, name, options);
                return common_1.createRequestFunction(localVarAxiosArgs, axios_1.default, base_1.BASE_PATH, configuration);
            });
        },
    };
};
/**
 * ProjectApi - factory interface
 * @export
 */
exports.ProjectApiFactory = function (configuration, basePath, axios) {
    const localVarFp = exports.ProjectApiFp(configuration);
    return {
        /**
         * Create a project
         * @param {InputCreateProject} [inputCreateProject] Create a project
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createProject(inputCreateProject, options) {
            return localVarFp.createProject(inputCreateProject, options).then((request) => request(axios, basePath));
        },
        /**
         * delete a project
         * @param {number | string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProject(id, options) {
            return localVarFp.deleteProject(id, options).then((request) => request(axios, basePath));
        },
        /**
         * Edit a project
         * @param {number | string} id
         * @param {InputCreateProject} [inputCreateProject] Edit a project
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editProject(id, inputCreateProject, options) {
            return localVarFp.editProject(id, inputCreateProject, options).then((request) => request(axios, basePath));
        },
        /**
         * Gets project by id
         * @param {number | string} id
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectById(id, options) {
            return localVarFp.getProjectById(id, options).then((request) => request(axios, basePath));
        },
        /**
         *
         * @param {number} [page]
         * @param {number} [size]
         * @param {string} [name]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjects(page, size, name, options) {
            return localVarFp.getProjects(page, size, name, options).then((request) => request(axios, basePath));
        },
    };
};
/**
 * ProjectApi - object-oriented interface
 * @export
 * @class ProjectApi
 * @extends {BaseAPI}
 */
class ProjectApi extends base_1.BaseAPI {
    /**
     * Create a project
     * @param {InputCreateProject} [inputCreateProject] Create a project
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    createProject(inputCreateProject, options) {
        return exports.ProjectApiFp(this.configuration).createProject(inputCreateProject, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * delete a project
     * @param {number | string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    deleteProject(id, options) {
        return exports.ProjectApiFp(this.configuration).deleteProject(id, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Edit a project
     * @param {number | string} id
     * @param {InputCreateProject} [inputCreateProject] Edit a project
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    editProject(id, inputCreateProject, options) {
        return exports.ProjectApiFp(this.configuration).editProject(id, inputCreateProject, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     * Gets project by id
     * @param {number | string} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    getProjectById(id, options) {
        return exports.ProjectApiFp(this.configuration).getProjectById(id, options).then((request) => request(this.axios, this.basePath));
    }
    /**
     *
     * @param {number} [page]
     * @param {number} [size]
     * @param {string} [name]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    getProjects(page, size, name, options) {
        return exports.ProjectApiFp(this.configuration).getProjects(page, size, name, options).then((request) => request(this.axios, this.basePath));
    }
}
exports.ProjectApi = ProjectApi;
