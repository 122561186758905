import {
  Button,
  Collapse,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";

import { AccountApi, AuthenticationApi } from "bonusx-api-main-manager";
import { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { useHistory } from "react-router";
import BetterCheckBox from "../../components/BetterCheckBox";
import NotVisibleIcon from "../../components/icons/not_visible";
import VisibleIcon from "../../components/icons/visible";
import useQueryParams from "../../hooks/useQueryParams";
import { apiErrorParser } from "../../utils";
import { useApi, useFetch } from "../../utils/api";

function RegisterPage() {
  const theme = useTheme();

  const authenticationApi = useApi(AuthenticationApi);
  const accountApi = useApi(AccountApi);

  const history = useHistory<any>();

  const querySearch = useQueryParams();
  const paramsEmail = querySearch.get("email");
  const [name, setName] = useState("");
  const [surname, setSurname] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [personalData, setPersonalData] = useState(false);

  const [emailBlocked, setEmailBlocked] = useState(false);

  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [errorMessageVisible, setErrorMessageVisible] = useState(false);

  const isMobile = useMediaQuery("@media only screen and (max-width:500px)");

  const [visiblePassword, setVisibilePassword] = useState(false);

  const [cookies] = useCookies();

  const isLogged = Boolean(cookies["clear3DAccessToken"]);

  const { data } = useFetch(accountApi.getMe, { skip: !isLogged });

  useEffect(() => {
    if (data?.email) {
      setEmail(data?.email);
      setEmailBlocked(true);
    }
  }, [data]);

  useEffect(() => {
    if (paramsEmail) {
      setEmail(paramsEmail);
      setEmailBlocked(true);
    }
  }, [paramsEmail]);

  //! test

  return (
    <Grid container direction="column" justify="center" alignItems="center" style={{ height: "100vh", background: "" }}>
      <Grid
        container
        direction="column"
        justify="center"
        alignItems="center"
        style={{ maxWidth: isMobile ? 300 : 510 }}
      >
        <Typography
          children="Crea il tuo Profilo"
          color="textPrimary"
          style={{
            fontSize: 20,
            fontWeight: "bold",
            marginTop: theme.spacing(2),
          }}
        />
        <Typography
          children="Inserisci le informazioni richieste. Troverai tutti i dati inseriti salvati nel tuo profilo, potrai facilmente richiedere le prestazioni per cui hai diritto."
          style={{ fontFamily: "Karla", fontSize: 16 }}
          color="textPrimary"
        />
        <Grid container style={{ marginTop: 20 }}>
          <Typography
            children="Inserisci i seguenti dati"
            style={{ fontFamily: "Karla", fontSize: 16, fontWeight: "bold" }}
            color="primary"
          />
        </Grid>

        <TextField
          value={name}
          style={{ marginTop: theme.spacing(2) }}
          fullWidth
          placeholder="Nome"
          variant="outlined"
          onChange={(event) => {
            setName(event.target.value);
          }}
        />
        <TextField
          value={surname}
          style={{ marginTop: theme.spacing(2) }}
          fullWidth
          placeholder="Cognome"
          variant="outlined"
          onChange={(event) => {
            setSurname(event.target.value);
          }}
        />
        <TextField
          value={email}
          disabled={emailBlocked}
          helperText={emailBlocked && "Questo campo è stato compilato automaticamente"}
          FormHelperTextProps={{
            style: { color: theme.palette.primary.main },
          }}
          style={{ marginTop: theme.spacing(2) }}
          fullWidth
          placeholder="E-mail"
          variant="outlined"
          onChange={(event) => {
            setEmail(event.target.value);
          }}
        />
        <TextField
          style={{ marginTop: theme.spacing(2) }}
          fullWidth
          variant="outlined"
          type={!visiblePassword ? "password" : "text"}
          name="password"
          placeholder="Password"
          value={password}
          onKeyPress={(ev) => {
            if (ev.key === "Enter" && !ev.shiftKey) {
              ev.preventDefault();
              invokeRegisterApi();
            }
          }}
          onChange={(event) => {
            // setErrorMessage("");
            // setErrorMessageVisible(false);
            setPassword(event.target.value);
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={(e) => {
                    setVisibilePassword((value) => !value);
                  }}
                  edge="end"
                  style={{ color: "black" }}
                >
                  {visiblePassword ? <VisibleIcon /> : <NotVisibleIcon />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <TextField
          value={phoneNumber}
          style={{ marginTop: theme.spacing(2) }}
          fullWidth
          placeholder="Telefono (opzionale)"
          variant="outlined"
          onChange={(event) => {
            setPhoneNumber(event.target.value);
          }}
        />
        <Collapse in={Boolean(errorMessageVisible)}>
          <Typography
            children={errorMessage}
            style={{
              marginTop: 16,
              color: theme.palette.warning.main,
              textAlign: "center",
            }}
          />
        </Collapse>

        <Grid container direction="row" style={{ marginTop: 20 }}>
          <Grid item style={{ width: 60 }}>
            <BetterCheckBox
              color="secondary"
              size="medium"
              checked={personalData}
              onChange={() => setPersonalData(!personalData)}
            />
          </Grid>
          <Grid item xs>
            <Typography
              color="textSecondary"
              style={{ fontSize: 12 }}
              children="Voglio essere aggiornato sulle prestazioni sociali e le agevolazioni a cui ho diritto e acconsento all’analisi delle mie informazioni per l'adeguamento delle comunicazioni alle mie esigenze (opzionale)"
            />
          </Grid>
        </Grid>

        <Button
          fullWidth
          style={{
            maxWidth: isMobile ? 280 : 300,
            marginTop: theme.spacing(2),
            marginBottom: theme.spacing(2),
          }}
          variant="contained"
          color="primary"
          children="CREA IL TUO PROFILO"
          disabled={!(name && surname && email && password)}
          onClick={() => {
            invokeRegisterApi();
          }}
        />
      </Grid>
    </Grid>
  );

  async function invokeRegisterApi() {
    if (email === "" || password === "" || name === "" || surname === "") {
      setErrorMessage("Devi compilare tutti i campi per continuare");
      toggleErrorMessageVisible(true);
      return;
    } else {
      // setErrorMessage("");
      toggleErrorMessageVisible(false);
    }

    try {
      const { data } = await authenticationApi.register({
        email,
        password,
        name,
        surname,
        phoneNumber,
      });

      if (data?.redisKey) {
        localStorage.clear();
        localStorage.setItem("ability-rules", JSON.stringify(data?.abilityRules || []));

        window.location.href = history?.location?.state?.from?.pathname || "/email-verification-sent";
      }
    } catch (axiosError) {
      const error = apiErrorParser(axiosError);

      setTimeout(() => {
        if (error) {
          switch (error) {
            case "email":
              setErrorMessage("Inserisci una mail valida");
              toggleErrorMessageVisible(true);
              break;
            case "password":
              setErrorMessage("Inserisci una password valida (almeno 8 caratteri)");
              toggleErrorMessageVisible(true);
              break;
            case "name":
              setErrorMessage("Un nome valido");
              toggleErrorMessageVisible(true);
              break;
            case "surname":
              setErrorMessage("Un cognome valido");
              toggleErrorMessageVisible(true);
              break;
            case "emailAlreadyRegistered":
              setErrorMessage("Questo indirizzo email è già registrato");
              toggleErrorMessageVisible(true);
              break;
            default:
              setErrorMessage(null);
              toggleErrorMessageVisible(false);
          }

          return;
        }
      }, 500);
    }
  }

  function toggleErrorMessageVisible(visible: boolean) {
    setTimeout(() => {
      setErrorMessageVisible(visible);
    }, 500);
  }
}

export default RegisterPage;
