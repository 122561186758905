import { useTheme } from "@material-ui/core";
import TemplateIcon, { calculateHighlightedSize, IIcon } from "./template";

const VisibleIcon = (props: IIcon) => {
  const theme = useTheme();
  const { size = 24, color = theme.palette.primary.main, highlighted } = props;

  const iconSize = highlighted && !props.HighlightProps?.forceRegularSize ? calculateHighlightedSize(size) : size;

  const icon = (
    <svg xmlns="http://www.w3.org/2000/svg" width={iconSize} height={iconSize} fill="none" viewBox="0 0 24 24">
      <path
        fill={color}
        d="M2.052 11.209C2.164 10.913 4.837 4 12 4s9.836 6.913 9.948 7.209a.85.85 0 010 .583c-.112.296-2.785 7.209-9.948 7.209s-9.836-6.913-9.948-7.209a.85.85 0 010-.583zm1.685.291c.554 1.25 2.965 5.834 8.263 5.834 5.254 0 7.709-4.621 8.263-5.834-.554-1.25-2.965-5.833-8.263-5.833-5.254 0-7.709 4.62-8.263 5.833zm8.264 3.54a3.542 3.542 0 100-7.083 3.542 3.542 0 000 7.084z"
      ></path>
    </svg>
  );

  return <TemplateIcon icon={icon} iconProps={props} />;
};

export default VisibleIcon;
